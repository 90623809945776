import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableRow as UnstyledTableRow,
  TableCell as UnstyledTableCell,
  Skeleton,
  Avatar
} from '@mui/material'
import { GREEN_PINE_OIL, TEAL_1 } from '../../../../constants/brand'
import SimplifiedCard from '../../../simplified_card'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import styled from '../../../../utils/styled'
import { useStore } from '../../../../store'
import { CalendarClock, Tag } from 'lucide-mui'
import isSameDay from 'date-fns/isSameDay'

const formatReservationRange = (from, until) => {
  const fromISO = parseISO(from)
  const untilISO = until ? parseISO(until) : null
  const sameDay = untilISO ? isSameDay(untilISO, fromISO) : false

  if (!untilISO) {
    return `${format(fromISO, 'MMM do, p')} (no limit)`
  }

  if (sameDay) {
    return `${format(fromISO, 'MMM do')}, ${format(fromISO, 'p')} - ${format(untilISO, 'p')}`
  } else {
    return `${format(fromISO, 'MMM do, p')} - ${format(untilISO, 'MMM do, p')}`
  }
}

const TableRow = styled(UnstyledTableRow)`
  ${(props) =>
    !props.withBottomBorder &&
    `
    &:last-child td, &:last-child th {
      border: 0
    }
  `}
`

const TableCell = styled(UnstyledTableCell)`
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ${(props) =>
    props.$collapse &&
    `
    padding-left: 0;
    padding-right: 0;
    width: 0;
    white-space: pre;
  `}
`

const ReservationContent = styled('div')`
  display: flex;
  align-items: flex-start;
  ${({ $active }) => $active && 'font-weight: 700;'}
`

const ReservationLineContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: ${({ theme, $color }) => $color || theme.palette.text.secondary};
  margin-top: ${({ theme, $small }) => theme.spacing($small ? 0.25 : 0.5)};
  font-size: ${({ theme, $small }) => ($small ? '0.75rem' : '0.875rem')};
`

const ReservationLine = ({ icon: Icon, children, small, ...props }) => (
  <ReservationLineContainer {...props} $small={small}>
    <Icon sx={{ fontSize: small ? 12 : 18, mr: small ? 0.5 : 0.75, mb: small ? 0.55 : 0.45 }} color='inherit' />
    <Typography variant='subtitle2' color='inherit' sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
      {children}
    </Typography>
  </ReservationLineContainer>
)

const ReservationsSkeleton = () => (
  <>
    <Skeleton variant='rectangular' sx={{ mt: 1.5, mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2, opacity: 0.75 }} animation='wave' />
  </>
)

const RecentReservationsCard = ({ partiallyLoaded, reservations }) => {
  const { users } = useStore()

  return (
    <SimplifiedCard title='Recent reservations' sx={{ mb: 2 }} noExtraBottomPadding>
      {partiallyLoaded && <ReservationsSkeleton />}
      {reservations.length > 0 && (
        <Table size='small' sx={{ mb: 1 }}>
          <TableBody>
            {reservations.map((reservation) => (
              <TableRow key={reservation.id} $active={reservation.active}>
                <TableCell>
                  <ReservationContent $active={reservation.active}>
                    <Avatar src={users.get(reservation.username)?.avatarUrl} sx={{ mr: 1, width: 20, height: 20 }} />
                    <div>
                      <Typography variant='body2' sx={{ fontWeight: 'inherit' }}>
                        {users.get(reservation.username)?.name || reservation.username}
                      </Typography>

                      <ReservationLine icon={CalendarClock} $color={GREEN_PINE_OIL} small>
                        {formatReservationRange(reservation.activeFrom, reservation.activeUntil)}
                      </ReservationLine>

                      {reservation.label && (
                        <ReservationLine icon={Tag} $color={TEAL_1} small>
                          {reservation.label}
                        </ReservationLine>
                      )}
                    </div>
                  </ReservationContent>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!partiallyLoaded && reservations.length === 0 && (
        <Typography variant='body1'>This testing instance has never been reserved</Typography>
      )}
    </SimplifiedCard>
  )
}

export default RecentReservationsCard
