import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ReservationForm from '../../../reservation_form'

const ReserveDialog = ({ name, activeReservation, loading, onClose, onReserve, ...props }) => {
  const [activeUntil, setActiveUntil] = useState(null)
  const [label, setLabel] = useState('')

  const onReserveClick = async () => {
    await onReserve(activeUntil, label)
    onClose()
  }

  return (
    <Dialog {...props}>
      <DialogTitle id='alert-dialog-title'>Reserve {name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ReservationForm
            activeUntil={activeUntil}
            setActiveUntil={setActiveUntil}
            label={label}
            setLabel={setLabel}
            single
            sx={{ width: 450, mt: 2, mb: 2 }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={onReserveClick} autoFocus>
          Reserve instance
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ReserveDialog
