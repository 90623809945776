import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

const fromMeta = (name) => document.querySelector(`meta[name="${name}"]`)?.getAttribute('content')
const release = fromMeta('current-version') || 'unknown'
const environment = fromMeta('environment') || 'development'

if (environment === 'development') {
  console.log('Sentry is not enabled in development mode')
} else {
  Sentry.init({
    dsn: 'https://c99bb65a350740d7a86a56e4d9c3a33b@o59295.ingest.sentry.io/6216926',

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release,
    environment,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}
