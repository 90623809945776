import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useLazyQuery } from '@apollo/client'
import { observer } from 'mobx-react'
import { FETCH_INSTANCE_STATUS, FETCH_INSTANCES } from '../../../api/instances'
import styled from '../../../utils/styled'
import { Autocomplete, Chip, IconButton, Stack, TextField } from '@mui/material'
import { COUNTRY_NAMES } from '../../../constants/countries'
import sortBy from 'lodash/sortBy'
import Close from 'mdi-material-ui/Close'
import ActionButton from '../../instance/action/button'
import { useStore } from '../../../store'

const ONE_MONTH_AGO = 1000 * 3600 * 24 * 30

const InstanceDetailsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.$busy && 'opacity: 0.5;'}
`

const appName = (build) => (build.appType === 'native' ? `${build.app} (native)` : build.app)
const pluralized = (builds) => (builds.length > 1 ? 'applications' : 'application')

const listed = (names) =>
  names.reduce((jsx, name, index) => {
    if (index === names.length - 1) {
      jsx.push(
        <React.Fragment key={index}>
          {' '}
          and <b>{name}</b>
        </React.Fragment>
      )
    } else if (index > 0) {
      jsx.push(
        <React.Fragment key={index}>
          , <b>{name}</b>
        </React.Fragment>
      )
    } else {
      jsx.push(
        <React.Fragment key={index}>
          <b>{name}</b>
        </React.Fragment>
      )
    }
    return jsx
  }, [])

const InstanceDetails = ({ instance, username }) => {
  const yours = instance.activeReservation?.username === username
  const busy = !!instance.activeReservation && instance.activeReservation.username !== username

  return (
    <InstanceDetailsContainer $busy={busy}>
      {instance.name}
      {yours && <Chip label='Reserved by you' color='success' size='small' variant='outlined' />}
      {busy && <Chip label='Taken' color='error' size='small' variant='outlined' />}
    </InstanceDetailsContainer>
  )
}

const Instances = ({ deployment, displayWarning }) => {
  const { loading, data } = useQuery(FETCH_INSTANCES)
  const checkInstanceStatus = useLazyQuery(FETCH_INSTANCE_STATUS)[0]
  const [alerted, addToAlerted] = useReducer((list, newElement) => list.add(newElement), new Set())
  const {
    session: {
      currentUser: { email }
    }
  } = useStore()

  const countryOf = (instance) => COUNTRY_NAMES[instance.country] || instance.country

  const options = sortBy(data?.instances || [], countryOf).map((instance) => {
    const latestJenkinsBuilds = instance.latestJenkinsBuilds.map((build) => ({
      ...build,
      updatedAt: Date.parse(build.updatedAt)
    }))
    const latestSemaphoreBuilds = instance.latestSemaphoreBuilds.map((build) => ({
      ...build,
      updatedAt: Date.parse(build.updatedAt)
    }))

    return { ...instance, latestJenkinsBuilds, latestSemaphoreBuilds }
  })

  const currentValue = deployment.instances
    .map((instance) => options.find((i) => i.name === instance.name))
    .filter(Boolean)

  useEffect(() => {
    const oneMonthAgo = Date.now() - ONE_MONTH_AGO
    currentValue.forEach((instance) => {
      if (!alerted.has(instance.name)) {
        const builds = instance.latestJenkinsBuilds.concat(instance.latestSemaphoreBuilds)
        const staleBuilds = builds.filter((build) => build.updatedAt < oneMonthAgo)

        if (staleBuilds.length > 0) {
          displayWarning({
            title: (
              <>
                {staleBuilds.length} {pluralized(staleBuilds)} on <b>{instance.name}</b> are out of date
              </>
            ),
            message: <>{listed(staleBuilds.map((build) => appName(build)))} should be updated to the latest version.</>,
            variant: 'info'
          })
        }

        checkInstanceStatus({ variables: { name: instance.name } }).then(
          ({
            data: {
              instance: { status }
            }
          }) => {
            if (status === 'stopped') {
              displayWarning({
                message: (
                  <span>
                    Instance <b>{instance.name}</b> is not running
                  </span>
                ),
                variant: 'error',
                important: true,
                action: ({ close }) => (
                  <Stack direction='row' spacing={1}>
                    <ActionButton
                      name={instance.name}
                      status={status}
                      variant='text'
                      onActioned={close}
                      startIcon={null}
                      color='inherit'
                    />
                    <IconButton onClick={close} size='small' color='inherit'>
                      <Close />
                    </IconButton>
                  </Stack>
                )
              })
            }
          }
        )

        addToAlerted(instance.name)
      }
    })
  }, [currentValue])

  return (
    <Autocomplete
      disabled={loading}
      onChange={(_, value) => deployment.setInstances(value)}
      value={currentValue}
      multiple
      options={options}
      groupBy={(instance) => countryOf(instance)}
      getOptionLabel={(instance) => instance.name}
      renderOption={(props, instance) => (
        <li {...props} key={instance.name}>
          <InstanceDetails instance={instance} username={email} />
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index} size='small' color='primary' label={option.name} {...getTagProps({ index })} />
        ))}
      renderInput={(params) => <TextField {...params} variant='standard' label='' />}
      isOptionEqualToValue={(option, value) => option.name === value.name}
    />
  )
}

Instances.propTypes = {
  deployment: PropTypes.shape({
    setInstances: PropTypes.func.isRequired
  })
}

export default observer(Instances)
