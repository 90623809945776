import React from 'react'
import { Typography } from '@mui/material'
import { VIOLET_1, WHITE } from '../../../../constants/brand'
import SimplifiedCard from '../../../simplified_card'
import styled from '../../../../utils/styled'
import useRefreshDb from '../../../instance/refresh_db/use_refresh_db'
import { AlertTriangle, Database } from 'lucide-mui'
import relativeDate from 'tiny-relative-date'
import LoadingButton from '@mui/lab/LoadingButton'
import ConfirmButton from '../../../confirm_button'

const DatabaseCardContainer = styled(SimplifiedCard).attrs(() => ({
  noExtraBottomPadding: true,
  fullColor: true,
  contentStyle: { flex: 1 }
}))`
  background: ${VIOLET_1};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

const StatusCardContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  color: ${WHITE};
  height: 100%;
  flex: 1;
`

const StatusCardDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StatusCardLine = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  &:first-child {
    margin-bottom: ${(props) => props.theme.spacing(0.75)};
  }
`

const StatusCardMetadata = styled('div')`
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1.25;
`

const DatabaseIconBackground = styled('div')`
  position: absolute;
  right: 0;
  bottom: -60px;
  color: #fff;
  opacity: 0.05;
  font-size: 140px;
  transform: rotate(5deg);
`

const DatabaseCard = ({ instance }) => {
  const { loading, warning, onClick } = useRefreshDb({ name: instance.name })
  const disabled = instance?.status !== 'running'
  const lastUpdated = instance.metadata?.databaseAge ? relativeDate(instance.metadata?.databaseAge) : 'N/A'

  return (
    <DatabaseCardContainer
      status={instance.status}
      actions={
        <ConfirmButton
          Component={LoadingButton}
          variant='text'
          color='inherit'
          loading={loading}
          disabled={disabled}
          onClick={onClick}
          messageContent={
            <>
              Are you sure you want to <b>refresh the database</b> on {instance.name}?
            </>
          }
          buttonYesTitle='Yes'
          buttonNoTitle='No'
          loadingPosition='start'
        >
          {loading ? 'Database is refreshing' : 'Refresh Database'}
        </ConfirmButton>
      }
      fullColor
    >
      <StatusCardContent>
        <StatusCardDetails>
          <StatusCardLine>
            <Typography variant='h4' color='inherit' sx={{ fontWeight: 700 }}>
              {disabled ? 'Database info unavailable' : `Last updated: ${lastUpdated}`}
            </Typography>
          </StatusCardLine>
          <StatusCardLine>
            {!!warning && (
              <StatusCardMetadata>
                <AlertTriangle fontSize='16' color='inherit' sx={{ mr: 1 }} /> There was an error refreshing the
                database
              </StatusCardMetadata>
            )}
          </StatusCardLine>
        </StatusCardDetails>
      </StatusCardContent>
      <DatabaseIconBackground>
        <Database fontSize='inherit' color='inherit' />
      </DatabaseIconBackground>
    </DatabaseCardContainer>
  )
}

export default DatabaseCard
