import React from 'react'
import PropTypes from 'prop-types'
import UnstyledTooltip from '../unstyled_tooltip'
import TooltipContent from './tooltip_content'

const Tooltip = ({ revision, ...props }) => (
  <UnstyledTooltip title={<TooltipContent message={revision.message} author={revision.author} />} {...props} />
)

Tooltip.propTypes = {
  revision: PropTypes.shape({
    message: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired
  })
}
export default Tooltip
