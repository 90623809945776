import React from 'react'
import { Box, Container } from '@mui/material'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { GREEN_SAGE_TEA } from '../constants/brand'

const FullpageLoader = () => (
  <Container sx={{ height: '100%' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100%' }}>
      <PropagateLoader color={GREEN_SAGE_TEA} />
    </Box>
  </Container>
)

export default FullpageLoader
