import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'

const RedeployButton = ({ id, issues, instances, disabled, ...props }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const redeploy = () => {
    ;(async function () {
      try {
        const keys = issues.map((issue) => issue.key)
        navigate('/create', { state: { jiraIssues: keys, instances } })
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    })()
  }

  return (
    <Button color='info' size='small' disabled={disabled} onClick={redeploy} variant='contained' {...props}>
      Redeploy Jira tickets
    </Button>
  )
}

RedeployButton.propTypes = {
  id: PropTypes.string
}

export default RedeployButton
