import React from 'react'
import ServerRemove from 'mdi-material-ui/ServerRemove'
import ServerPlus from 'mdi-material-ui/ServerPlus'
import ConfirmButton from '../../confirm_button'
import useAction from './use_action'
import { confirmProps } from '../../../constants/instances'
import LoadingButton from '@mui/lab/LoadingButton'

const InstanceActionButton = ({ name, status, onActioned = () => null, variant = 'outlined', ...props }) => {
  const { loading, onStart, onStop } = useAction({ name, status, onActioned })

  const runningOrStopping = status === 'running' || status === 'stopping'
  const stoppedOrStarting = status === 'stopped' || status === 'starting'

  if (!runningOrStopping && !stoppedOrStarting) {
    return null
  }

  return (
    <ConfirmButton
      Component={LoadingButton}
      variant={variant}
      color={runningOrStopping ? 'error' : 'success'}
      loading={loading}
      loadingPosition='center'
      startIcon={runningOrStopping ? <ServerRemove /> : <ServerPlus />}
      onClick={runningOrStopping ? onStop : onStart}
      {...confirmProps({ name, status })}
      {...props}
    >
      {runningOrStopping ? 'Stop' : 'Start'}
    </ConfirmButton>
  )
}

export default InstanceActionButton
