import React from 'react'
import PropTypes from 'prop-types'
import { Link, CircularProgress } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { REVISION_DETAILS } from '../../api/github'
import useDebouncedEffect from 'use-debounced-effect-hook'
import { githubCommitUrl } from '../../utils/urls'
import RevisionTooltip from './tooltip'

const Revision = ({ app, revision, label = null, short = false, lazyLoad = false }) => {
  const [getDetails, { loading, data: revisionData }] = useLazyQuery(REVISION_DETAILS)
  const reload = () => getDetails({ variables: { appName: app, branchOrRevision: revision } })
  const display = (sha) => label || (sha && short ? sha.substring(0, 7) : sha)

  useDebouncedEffect(() => !!revision && !lazyLoad && reload(), [app, revision], 1000)

  if (lazyLoad && !revisionData?.revision) {
    return (
      <Link href={githubCommitUrl(app, revision)} onMouseOver={() => !!revision && reload()}>
        {display(revision)}
        {loading && <CircularProgress size={12} sx={{ ml: 1 }} />}
      </Link>
    )
  }

  if (loading || !revisionData?.revision) {
    return <Link href={githubCommitUrl(app, revision)}>{display(revision)}</Link>
  }

  const revisionDetails = revisionData.revision

  return (
    <RevisionTooltip revision={revisionDetails}>
      <Link href={revisionDetails.url}>{display(revisionDetails.sha)}</Link>
    </RevisionTooltip>
  )
}

Revision.propTypes = {
  app: PropTypes.string.isRequired,
  revision: PropTypes.string.isRequired
}

export default Revision
