import { UNKNOWN, SUCCESS, FAILURE, IN_PROGRESS, PENDING } from '../constants/statuses'
import { CTA_GREEN, CTA_SALMON, GRAY_3, TEAL_2 } from '../constants/brand'

export const getDeploymentStatus = (deployment) => {
  if (!deployment) {
    return null
  }

  const {
    builds: { nodes: builds }
  } = deployment

  const statuses = builds.map((build) => build.status)

  if (statuses.length === 0) {
    return UNKNOWN
  }

  if (statuses.every((status) => status === SUCCESS)) {
    return SUCCESS
  }

  if (statuses.some((status) => status === FAILURE)) {
    return FAILURE
  }

  if (statuses.some((status) => status === IN_PROGRESS)) {
    return IN_PROGRESS
  }

  if (statuses.every((status) => status === PENDING)) {
    return PENDING
  }

  if (statuses.some((status) => status === PENDING)) {
    return IN_PROGRESS
  }

  return UNKNOWN
}

export const getFavicon = (status) => {
  if (status === UNKNOWN) return 'warning'
  if (status === SUCCESS) return 'success'
  if (status === FAILURE) return 'error'

  return 'generic'
}

export const getColors = (status) => {
  if (status === SUCCESS) return [CTA_GREEN, CTA_GREEN, true]
  if (status === FAILURE) return [CTA_SALMON, CTA_SALMON, true]
  if (status === IN_PROGRESS) return [TEAL_2, TEAL_2, true]

  return [GRAY_3, GRAY_3, false]
}
