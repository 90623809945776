import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import styled from '../../../utils/styled'

const StyledAlert = styled(Alert)`
  margin-top: ${(props) => props.theme.spacing(1)};

  & .MuiAlert-message {
    flex: 1;
  }

  & .MuiAlert-action {
    padding-top: 0;
  }
`

const Warnings = ({ warnings, closeWarning, ...props }) => {
  return warnings.map((warning) => (
    <StyledAlert
      key={warning}
      severity={warning.variant}
      action={
        typeof warning.action === 'function'
          ? warning.action({ warning, close: () => closeWarning(warning) })
          : warning.action
      }
      onClose={() => closeWarning(warning)}
      variant={warning.important ? 'filled' : 'outlined'}
      {...props}
    >
      {!!warning.title && <AlertTitle>{warning.title}</AlertTitle>}
      {warning.message}
    </StyledAlert>
  ))
}

Warnings.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.node.isRequired,
      title: PropTypes.node,
      variant: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
      action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      important: PropTypes.bool
    })
  )
}
export default Warnings
