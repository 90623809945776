import { gql } from '@apollo/client'

export const FETCH_SESSION_DATA = gql`
  query sessionData {
    currentUser {
      id
      name
      email
      avatarUrl
    }
    users {
      name
      email
      avatarUrl
    }
  }
`

export const MIGRATE = gql`
  mutation migrate($jenkinsUsername: String!) {
    migrate(jenkinsUsername: $jenkinsUsername) {
      success
    }
  }
`
