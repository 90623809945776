import { useLocation, useNavigate } from 'react-router'

export const normalizeJiraIssueKey = (key) => {
  const match = key.match(/https:\/\/helpling\.atlassian\.net\/browse\/([^/]+)\/?/)
  if (match && match[1]) {
    return match[1]
  }
  return key
}

export const useCreateJiraIssues = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isOnCreatePage = location?.pathname === '/create'
  const jiraIssues = (isOnCreatePage ? location.state?.jiraIssues || [] : []).map(normalizeJiraIssueKey)

  const addJiraIssue = (issue) => {
    const issueKey = normalizeJiraIssueKey(issue)
    const isNewIssue = !jiraIssues.some((jiraIssue) => jiraIssue === issueKey)

    if (!isOnCreatePage || isNewIssue) {
      const newJiraIssues = isNewIssue ? [...jiraIssues, issueKey] : jiraIssues
      navigate('/create', { state: { ...(location.state || {}), jiraIssues: newJiraIssues } })
      return true
    } else {
      return false
    }
  }
  const removeJiraIssue = (issue) => {
    const issueKey = normalizeJiraIssueKey(issue)
    const newJiraIssues = jiraIssues.filter((i) => i !== issueKey)
    navigate('/create', { state: { ...(location.state || {}), jiraIssues: newJiraIssues } })
  }

  return { jiraIssues, addJiraIssue, removeJiraIssue }
}
