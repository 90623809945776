import React from 'react'
import ReactDOM from 'react-dom'
import App from '../app'

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('root')
  const getProps = () => {
    try {
      return JSON.parse(root.dataset.props) || {}
    } catch (e) {
      return {}
    }
  }

  ReactDOM.render(<App {...getProps()} />, root)
})
