import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const ConfirmButton = React.forwardRef(
  (
    {
      Component = Button,
      messageTitle,
      messageContent,
      buttonYesTitle = 'Continue',
      buttonNoTitle = 'Cancel',
      onClick: originalOnClick,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState(false)
    const onOpen = useCallback((e) => {
      e.stopPropagation()
      setOpen(true)
    }, [])
    const onClose = useCallback((e) => {
      e.stopPropagation()
      setOpen(false)
    }, [])
    const onAccept = useCallback(
      (e) => {
        e.stopPropagation()
        originalOnClick(e)
        onClose(e)
      },
      [originalOnClick]
    )

    return (
      <>
        <Component {...props} onClick={onOpen} ref={ref} />
        <Dialog open={open} onClose={onClose}>
          {!!messageTitle && <DialogTitle id='alert-dialog-title'>{messageTitle}</DialogTitle>}
          <DialogContent>
            <DialogContentText>{messageContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{buttonNoTitle}</Button>
            <Button onClick={onAccept} autoFocus>
              {buttonYesTitle}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
)

ConfirmButton.propTypes = {
  Component: PropTypes.elementType,
  messageTitle: PropTypes.node,
  messageContent: PropTypes.node.isRequired,
  buttonYesTitle: PropTypes.node,
  buttonNoTitle: PropTypes.node,
  onClick: PropTypes.func.isRequired
}

export default ConfirmButton
