import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Typography, Link, IconButton } from '@mui/material'
import RevisionTooltip from '../../../revision/tooltip'
import { BACKEND, NATIVE, WEB } from '../../../../constants/app_types'
import RevisionError from './error'
import { useLazyQuery } from '@apollo/client'
import { REVISION_DETAILS } from '../../../../api/github'
import useDebouncedEffect from 'use-debounced-effect-hook'
import Refresh from 'mdi-material-ui/Refresh'

const ReloadButton = ({ onClick }) => (
  <IconButton onClick={onClick} size='small' sx={{ padding: '1px', ml: 0.5, mt: '-1px' }}>
    <Refresh sx={{ width: '14px', height: '14px' }} />
  </IconButton>
)
ReloadButton.propTypes = { onClick: PropTypes.func.isRequired }

const RevisionDetails = ({
  app,
  branchOrRevision,
  displayReloadButton = true,
  displayError = true,
  displayLoading = true
}) => {
  const [getDetails, { loading, data: revisionData }] = useLazyQuery(REVISION_DETAILS)
  const reload = () => getDetails({ variables: { appName: app.app, branchOrRevision: branchOrRevision } })

  useDebouncedEffect(() => !!branchOrRevision && reload(), [app, branchOrRevision], 1000)

  if (loading) {
    if (!displayLoading) {
      return branchOrRevision
    }

    return <CircularProgress size={16} />
  }

  const revision = revisionData?.revision

  if (revision === null) {
    if (!displayError) {
      return branchOrRevision
    }

    return (
      <>
        <RevisionError message='Revision or branch does not exist' />
        {displayReloadButton && <ReloadButton onClick={reload} />}
      </>
    )
  }

  if (!revision) {
    return branchOrRevision
  }

  if (revision.source === 'revision' && app.appType !== BACKEND) {
    if (!displayError) {
      return branchOrRevision
    }

    return (
      <>
        <RevisionError message='Git commit revisions can only be used for h2 deployments' />
        {displayReloadButton && <ReloadButton onClick={reload} />}
      </>
    )
  }

  return (
    <RevisionTooltip revision={revision}>
      <Typography variant='caption'>
        revision: <Link href={revision.url}>{revision.sha.slice(0, 7)}</Link>
        {displayReloadButton && <ReloadButton onClick={reload} />}
      </Typography>
    </RevisionTooltip>
  )
}

RevisionDetails.propTypes = {
  app: PropTypes.shape({
    app: PropTypes.string.isRequired,
    appType: PropTypes.oneOf([BACKEND, WEB, NATIVE]).isRequired
  }).isRequired,
  branchOrRevision: PropTypes.string
}

export default RevisionDetails
