import React from 'react'
import { Container, Typography } from '@mui/material'
import Title from '../../title'
import Alert from 'mdi-material-ui/AlertOctagon'
import styled from '../../../utils/styled'

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(30)};
`

const Message = styled(Typography).attrs(() => ({
  variant: 'h4'
}))`
  display: flex;
  flex: 1;
  flex-direction: Row;
  align-items: center;
  color: #000;
`

const AlertIcon = styled(Alert)`
  width: 32px;
  height: 32px;
  margin-right: 16px;
`

const NotFound = () => (
  <Content>
    <Title title='Not found' />
    <Message>
      <AlertIcon /> Could not find this deployment
    </Message>
  </Content>
)

export default NotFound
