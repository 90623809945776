export const COUNTRY_NAMES = {
  AU: 'Australia',
  CH: 'Switzerland',
  DE: 'Germany',
  FR: 'France',
  IT: 'Italy',
  IE: 'Ireland',
  NL: 'Netherlands',
  UK: 'UK',
  SG: 'Singapore'
}
