import React from 'react'
import PropTypes from 'prop-types'
import { Button, Box, Typography } from '@mui/material'
import { QRCodeSVG } from 'qrcode.react'
import Download from 'mdi-material-ui/Download'
import BaseInstructions from './base'

const AndroidInstructions = ({ build }) => {
  if (!build) {
    return null
  }

  return (
    <BaseInstructions
      version={build.version}
      runtimeVersion={build.runtimeVersion}
      header='To install the app on Android device'
      footer={
        <>
          Either scan the QR Code or download the file and move it manually to your device. Open the file on your
          device.
          <br />
          You may need to allow installation from unknown sources.
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <QRCodeSVG value={build.buildUrl} />
        <Typography variant='body2' align='center' paragraph sx={{ mt: 1, mb: 1 }}>
          or
        </Typography>
        <Button variant='outlined' color='primary' href={build.buildUrl} download startIcon={<Download />}>
          Download this file
        </Button>
      </Box>
    </BaseInstructions>
  )
}

AndroidInstructions.propTypes = {
  build: PropTypes.shape({
    appName: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    runtimeVersion: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    gitRevision: PropTypes.string.isRequired,
    buildUrl: PropTypes.string.isRequired
  })
}

export default AndroidInstructions
