import React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import RevisionDetails from './revision_details'
import { observer } from 'mobx-react'

const StyledAutocomplete = styled(Autocomplete)`
  display: flex;
  flex: 1;
  width: 100%;
`

const Input = ({ app, options, placeholder, onChange, value, ...props }) => {
  return (
    <StyledAutocomplete
      autoComplete
      freeSolo
      options={options || []}
      includeInputInList
      value={value}
      onChange={(e, value) => onChange(value)}
      onInputChange={(e, value) => onChange(value)}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          label={placeholder}
          {...props}
          helperText={<RevisionDetails app={app} branchOrRevision={value} />}
        />
      )}
    />
  )
}

export default observer(Input)
