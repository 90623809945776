import React from 'react'
import { Container, Grid } from '@mui/material'
import Title from '../../title'
import JiraForm from '../../jira_form'
import { GREEN_GROWN_UP_GREEN } from '../../../constants/brand'
import LatestDeployments from './latest_deployments'
import { useStore } from '../../../store'
import ReservedInstances from './reserved_instances'

const Index = () => {
  const store = useStore()
  const username = store.session.currentUser.email

  return (
    <Container>
      <Title colors={[GREEN_GROWN_UP_GREEN, GREEN_GROWN_UP_GREEN]} />
      <JiraForm sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item md={7} sm={12} xs={12}>
          {!!username && <LatestDeployments username={username} />}
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          {!!username && <ReservedInstances username={username} />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Index
