import { gql } from '@apollo/client'

export const FETCH_JIRA_ISSUES_FOR_AUTOCOMPLETE = gql`
  query jiraIssuesForAutocomplete {
    jiraIssues {
      title
      key
      url
      team
      priority
    }
  }
`

export const FETCH_JIRA_ISSUE = gql`
  query jiraIssue($key: String!) {
    jiraIssue(key: $key) {
      title
      description
      key
      url
      team
      priority
    }
  }
`

export const FETCH_JIRA_VERSIONS = gql`
  query fetchJiraVersions($keys: [String!]!) {
    jiraIssueVersions(keys: $keys) {
      versions {
        app
        branch
        revision
      }
      warnings
    }
  }
`
