import React from 'react'
import { Container, Card, Typography, CardContent } from '@mui/material'
import styled from '../utils/styled'
import HeartBroken from 'mdi-material-ui/HeartBroken'

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(10)};
`

const Message = styled(Typography).attrs(() => ({
  variant: 'h4'
}))`
  display: flex;
  flex: 1;
  flex-direction: Row;
  align-items: center;
  color: #000;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`

const ErrorBlock = styled(Card).attrs(() => ({
  component: 'pre',
  elevation: 2
}))`
  background: #d0d0d0;
  padding-bottom: ${(props) => props.theme.spacing(1)};
`

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    return { hasError: true, error }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ errorInfo })
    console.error(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      const errorMessage = typeof this.state.error === 'string' ? this.state.error : this.state.error?.message
      const componentStack = this.state.errorInfo?.componentStack
      const stack = typeof this.state.error === 'string' ? null : this.state.error?.stack

      return (
        <Content>
          <Message>
            <HeartBroken sx={{ width: 48, height: 48, marginRight: 2 }} /> Unfortunately, the app has crashed
          </Message>
          {errorMessage && (
            <>
              <Message variant='body2'>Send over this error to #squirrel on Slack and we will try to fix it:</Message>
              <ErrorBlock>
                <CardContent>
                  {errorMessage}
                  {!!errorMessage && !!stack && '\n'}
                  {stack}
                  {!!stack && !!componentStack && '\n'}
                  {componentStack}
                </CardContent>
              </ErrorBlock>
            </>
          )}
        </Content>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
