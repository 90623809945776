import React from 'react'
import { Link } from 'react-router-dom'
import styled from '../../../../utils/styled'

// Dot that can be either filled or stroked, with customizable size and color.
// It also does not overflow its viewBox
export const Dot = ({ size, color, strokeWidth = 0, strokeColor }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <circle
      cx={size / 2}
      cy={size / 2}
      r={Math.max(0, size / 2 - strokeWidth)}
      fill={color}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
)

export const Submenu = styled('div')`
  margin: ${({ theme, $empty }) => theme.spacing(-1.5, 0, $empty ? 0 : 1.5, 0)};
  min-height: ${({ theme }) => theme.spacing(1.5)};
`

export const SubmenuItem = styled('div')`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(0.5, 0)};
  font-size: 14px;
  font-weight: ${({ $isActive }) => ($isActive ? '700' : '400')};
`
export const SubmenuIcon = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`
export const SubmenuLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  margin-left: ${({ theme }) => theme.spacing(1)};
  flex: 1;
`
