import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Typography, Link } from '@mui/material'
import LinearProgressWithLabel from './progress_with_label'
import styled from '../../../utils/styled'

const Error = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  component: 'span'
}))`
  color: ${(props) => props.theme.palette.error.main};
`

const DetailsLink = ({ build }) =>
  build.detailsUrl
    ? (
      <Tooltip title='Open the build status in Jenkins'>
        <Link href={build.detailsUrl} target='_blank' rel='noopener noreferrer' color='inherit' underline='hover'>
          (details)
        </Link>
      </Tooltip>
      )
    : null

const SecondaryLabel = ({ build }) => {
  if (build.error) {
    return (
      <Tooltip title={build.error} color='error'>
        <Error>
          Error: {build.error} <DetailsLink build={build} />
        </Error>
      </Tooltip>
    )
  }

  if (build.status === 'awaiting_artifact') {
    return (
      <Typography variant='subtitle2' color='secondary' component='span'>
        Awaiting for Jenkins to build this commit <DetailsLink build={build} />
      </Typography>
    )
  }

  if (build.status === 'unknown') {
    return (
      <Error>
        The build has not finished in a reasonable amount of time <DetailsLink build={build} />
      </Error>
    )
  }

  return <LinearProgressWithLabel value={build.progress} variant={build.progress ? 'determinate' : 'indeterminate'} />
}

SecondaryLabel.propTypes = {
  build: PropTypes.shape({
    error: PropTypes.string,
    status: PropTypes.string,
    progress: PropTypes.number
  })
}

export default SecondaryLabel
