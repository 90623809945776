import React from 'react'
import PropTypes from 'prop-types'

import AndroidInstructions from './instructions/android'
import IosInstructions from './instructions/ios'
import AndroidSimulatorInstructions from './instructions/android_simulator'
import IosSimulatorInstructions from './instructions/ios_simulator'
import IosDeviceInstructions from './instructions/ios_device'
import AndroidDeviceInstructions from './instructions/android_device'
import MissingInstructions from './instructions/missing'

const Instructions = ({ build, environment, platform, isRealDevice }) => {
  if (!environment || !platform) {
    return null
  }

  if (!build) {
    return <MissingInstructions environment={environment} platform={platform} />
  }

  if (isRealDevice) {
    if (build.platform === 'android') {
      return <AndroidDeviceInstructions build={build} />
    }

    if (build.platform === 'ios') {
      return <IosDeviceInstructions build={build} />
    }
  }

  if (build.platform === 'android' && build.environment === 'development') {
    return <AndroidSimulatorInstructions build={build} />
  }

  if (build.platform === 'ios' && build.environment === 'development') {
    return <IosSimulatorInstructions build={build} />
  }

  if (build.platform === 'android') {
    return <AndroidInstructions build={build} />
  }

  if (build.platform === 'ios') {
    return <IosInstructions build={build} />
  }

  return null
}

Instructions.propTypes = {
  build: PropTypes.shape({
    appName: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    runtimeVersion: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    gitRevision: PropTypes.string.isRequired,
    buildUrl: PropTypes.string.isRequired
  })
}

export default Instructions
