import React from 'react'
import { Typography, Avatar } from '@mui/material'
import { GRAY_5, GREEN_PINE_OIL, GREEN_SUMMER_GRASS, TEAL_1 } from '../../../../constants/brand'
import SimplifiedCard from '../../../simplified_card'
import parseISO from 'date-fns/parseISO'
import ReserveButton from '../../../instance/reserve/button'
import styled from '../../../../utils/styled'
import { useStore } from '../../../../store'
import { CalendarClock, Tag } from 'lucide-mui'
import formatRelative from 'date-fns/formatRelative'

const ActiveReservationContainer = styled(SimplifiedCard).attrs(() => ({
  noExtraBottomPadding: true,
  contentStyle: { flex: 1 }
}))`
  outline: 1px solid ${GREEN_SUMMER_GRASS};
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ActiveReservationSkeleton = styled('div')`
  background: ${GRAY_5};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  height: 100px;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`

const ActiveReservationContent = styled('div')`
  display: flex;
  align-items: center;
`

const EmptyReservationContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const ReservationLineContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: ${({ theme, $color }) => $color || theme.palette.text.secondary};
  margin-top: ${({ theme, $small }) => theme.spacing($small ? 0.25 : 0.5)};
  font-size: ${({ theme, $small }) => ($small ? '0.75rem' : '0.875rem')};
`

const ReservationLine = ({ icon: Icon, children, small, ...props }) => (
  <ReservationLineContainer {...props} $small={small}>
    <Icon sx={{ fontSize: small ? 12 : 18, mr: small ? 0.5 : 0.75, mb: small ? 0.55 : 0.45 }} color='inherit' />
    <Typography variant='subtitle2' color='inherit' sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
      {children}
    </Typography>
  </ReservationLineContainer>
)

const ActiveReservationCard = ({ partiallyLoaded, instance, activeReservation }) => {
  const { users } = useStore()
  const activeReservationUser = users.get(activeReservation?.username)

  if (partiallyLoaded) {
    return <ActiveReservationSkeleton />
  }

  if (activeReservation) {
    return (
      <ActiveReservationContainer
        actions={
          <>
            <ReserveButton
              variant='text'
              startIcon={undefined}
              name={instance.name}
              activeReservation={instance.activeReservation}
            />
          </>
        }
      >
        <ActiveReservationContent>
          <Avatar src={activeReservationUser?.avatarUrl} sx={{ mr: 3, width: 64, height: 64 }} />

          <div>
            <Typography variant='h4' color='text' sx={{ fontWeight: 600 }}>
              {activeReservationUser.name || activeReservation.username}
            </Typography>
            <ReservationLine icon={CalendarClock} $color={GREEN_PINE_OIL}>
              {activeReservation.activeUntil
                ? `Until ${formatRelative(parseISO(activeReservation.activeUntil), new Date())}`
                : 'Reserved with no time limit'}
            </ReservationLine>

            {activeReservation.label && (
              <ReservationLine icon={Tag} $color={TEAL_1}>
                {activeReservation.label}
              </ReservationLine>
            )}
          </div>
        </ActiveReservationContent>
      </ActiveReservationContainer>
    )
  }

  return (
    <ActiveReservationContainer
      actions={
        <>
          <ReserveButton
            variant='text'
            startIcon={undefined}
            name={instance.name}
            activeReservation={instance.activeReservation}
          />
        </>
      }
    >
      <EmptyReservationContent>
        <Typography variant='h4' color='text' sx={{ fontWeight: 600 }}>
          Available for reservation
        </Typography>
      </EmptyReservationContent>
    </ActiveReservationContainer>
  )
}

export default ActiveReservationCard
