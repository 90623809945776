import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import IssuePriority from './issue_priority'
import styled from '../utils/styled'

const Status = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  color: 'textSecondary',
  display: 'inline'
}))`
  margin-right: ${(props) => props.theme.spacing(0.5)};
`

const JiraDetails = ({ team, status, priority }) => (
  <Grid container alignItems='center' justifyContent='space-between'>
    <Grid item>
      <Typography variant='subtitle2' color='textSecondary' display='block'>
        Team: {team}
      </Typography>
    </Grid>
    <Grid item>
      <Grid container alignItems='center'>
        <Status>{status}</Status>
        <IssuePriority priority={priority} />
      </Grid>
    </Grid>
  </Grid>
)

JiraDetails.propTypes = {
  team: PropTypes.string,
  status: PropTypes.string,
  priority: PropTypes.oneOf(['Highest', 'High', 'Medium', 'Low', 'Lowest'])
}

export default JiraDetails
