import React from 'react'
import styled from '../../../utils/styled'
import { keyframes, css } from 'styled-components'
import Check from 'mdi-material-ui/Check'
import Close from 'mdi-material-ui/Close'
import ExclamationThick from 'mdi-material-ui/ExclamationThick'
import DotsHorizontal from 'mdi-material-ui/DotsHorizontal'

const breatheAnimation = keyframes`
 0% { opacity: 0.4 }
 50% { opacity: 0.8 }
 100% { opacity: 0.4 }
`

const Container = styled('div')`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  background-color: ${({ status, theme: { palette } }) =>
    ({
      pending: '#ccc',
      in_progress: palette.info.main,
      success: palette.success.main,
      failure: palette.error.main,
      unknown: palette.warning.main
    }[status] || '#ccc')};

  ${(props) =>
    props.status === 'in_progress' &&
    css`
      animation-name: ${breatheAnimation};
      animation-duration: 4s;
      animation-iteration-count: infinite;
    `}
`

const StatusIndicator = ({ progress, status, size = 20, className, ...props }) => (
  <Container size={size} status={status} {...props}>
    {status === 'initial' && <DotsHorizontal style={{ width: size, height: size, fill: '#fff' }} />}
    {status === 'in_progress' && <DotsHorizontal style={{ width: size, height: size, fill: '#fff' }} />}
    {status === 'success' && <Check style={{ width: 14, height: 14, fill: '#fff' }} />}
    {status === 'failure' && <Close style={{ width: 14, height: 14, fill: '#fff' }} />}
    {status === 'unknown' && <ExclamationThick style={{ width: 14, height: 14, fill: '#fff' }} />}
  </Container>
)

export default StatusIndicator
