import React from 'react'
import { styled as muiStyled, useTheme } from '@mui/material/styles'

// "I DON't SeE a big VaLue oF AddiNg thIs APi."
// — https://github.com/mui-org/material-ui/issues/29492#issuecomment-992365260
const styled = (StyledComponent, ...args) => {
  const Component = muiStyled(StyledComponent, ...args)
  Component.attrs = (defaultProps) =>
    muiStyled(
      React.memo((props) => {
        const theme = useTheme()
        return <StyledComponent {...defaultProps({ theme, ...props })} {...props} />
      }),
      ...args
    )
  return Component
}

export * from '@mui/material/styles'
export default styled
