import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLazyQuery } from '@apollo/client'
import { FETCH_SESSION_DATA } from '../api/session'
import { useStore } from '../store'

const SessionManager = ({ children }) => {
  const store = useStore()
  const fetchSessionData = useLazyQuery(FETCH_SESSION_DATA)[0]
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!store.session.loaded) {
      fetchSessionData().then(({ data: { currentUser, users } }) => {
        store.session.setCurrentUser(currentUser)
        store.users.load(users)
        setReady(true)
      })
    } else {
      setReady(true)
    }
  }, [])

  if (!ready) {
    return null
  }

  if (!store.session.isSignedIn) {
    window.location.replace('/login')
    return null
  }

  return children
}

export default observer(SessionManager)
