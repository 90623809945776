import React from 'react'
import styled from '../../../utils/styled'
import Logo from '../logo'
import { observer } from 'mobx-react'
import { GREEN_PINE_OIL } from '../../../constants/brand'

const Container = styled('div')`
  height: 48px;
  width: 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  background: ${GREEN_PINE_OIL};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const MobileHeader = () => {
  return (
    <Container>
      <Logo />
    </Container>
  )
}

export default observer(MobileHeader)
