import React, { useEffect } from 'react'
import { Box, Tabs, Tab, Container } from '@mui/material'
import { useQuery } from '../../../utils/apollo'
import { FETCH_NATIVE_APP_BUILDS } from '../../../api/native_apps'
import Title from '../../title'
import { VIOLET_4, VIOLET_1 } from '../../../constants/brand'
import uniq from 'lodash/uniq'
import App from './app'
import { useParams, useNavigate } from 'react-router'
import FullpageLoader from '../../fullpage_loader'

const getAvailableApps = ({ nativeAppBuilds }) => uniq(nativeAppBuilds.map((build) => build.appName)).sort()
const Apps = () => {
  const navigate = useNavigate()
  const { appName: currentApp } = useParams()
  const setCurrentApp = (appName) => {
    if (currentApp !== appName) {
      navigate(`/apps/${appName}`)
    }
  }

  const { loading, data, refetch } = useQuery(FETCH_NATIVE_APP_BUILDS, {
    onCompleted: (data) => {
      if (!currentApp) {
        setCurrentApp(getAvailableApps(data)[0])
      }
    }
  })

  useEffect(() => {
    if (!loading && !currentApp) {
      refetch()
    }
  }, [currentApp])

  if (loading || !currentApp) {
    return <FullpageLoader />
  }

  const availableApps = getAvailableApps(data)

  return (
    <Container>
      <Title title='Apps' colors={[VIOLET_1, VIOLET_4]} />

      <Box sx={{ marginTop: '-10px' }}>
        <Tabs
          value={currentApp}
          onChange={(_e, value) => setCurrentApp(value)}
          textColor='inherit'
          indicatorColor='secondary'
        >
          {availableApps.map((appName) => (
            <Tab key={appName} value={appName} label={appName} />
          ))}
        </Tabs>
      </Box>

      <App
        key={currentApp}
        appName={currentApp}
        builds={data.nativeAppBuilds.filter((app) => app.appName === currentApp)}
      />
    </Container>
  )
}

export default Apps
