const statusColor = (status, palette) => {
  if (status === 'running') {
    return palette.success.main
  } else if (status === 'stopped') {
    return palette.error.main
  } else if (status === 'starting' || status === 'stopping') {
    return palette.secondary.main
  } else {
    return palette.warning.main
  }
}
export default statusColor
