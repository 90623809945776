import React from 'react'
import PropTypes from 'prop-types'
import styled from '../../utils/styled'
import { useStore } from '../../store'
import Tooltip from '../unstyled_tooltip'
import TooltipContent from './tooltip'

const UserName = styled('span')`
  cursor: pointer;
`

const User = ({ email, children, ...props }) => {
  const {
    users: { get }
  } = useStore()

  const user = get(email)

  if (!user) {
    return email
  }

  return (
    <Tooltip title={<TooltipContent {...user} />} followCursor>
      {children || <UserName {...props}>{user.name}</UserName>}
    </Tooltip>
  )
}

User.propTypes = {
  email: PropTypes.string.isRequired,
  children: PropTypes.node
}
export default User
