import React from 'react'
import PropTypes from 'prop-types'
import { Button, Box } from '@mui/material'
import Download from 'mdi-material-ui/Download'
import BaseInstructions from './base'

const AndroidInstructions = ({ build }) => {
  if (!build) {
    return null
  }

  return (
    <BaseInstructions
      condensed
      version={build.version}
      runtimeVersion={build.runtimeVersion}
      header='To install the app on this Android device'
      footer={<>You may need to allow installation from unknown sources.</>}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant='outlined' color='primary' href={build.buildUrl} download startIcon={<Download />}>
          Download this file
        </Button>
      </Box>
    </BaseInstructions>
  )
}

AndroidInstructions.propTypes = {
  build: PropTypes.shape({
    appName: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    runtimeVersion: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    gitRevision: PropTypes.string.isRequired,
    buildUrl: PropTypes.string.isRequired
  })
}

export default AndroidInstructions
