import React from 'react'
import AU from './country-flags/austallia-flag-country-nation-union-empire-32912.svg'
import CH from './country-flags/switzerland-flag-country-nation-union-empire-33095.svg'
import DE from './country-flags/germany-flag-country-nation-union-empire-32989.svg'
import FR from './country-flags/france-flag-country-nation-union-empire-32967.svg'
import IT from './country-flags/italy-flag-country-nation-union-empire-32999.svg'
import IE from './country-flags/ireland-flag-country-nation-union-empire-32996.svg'
import NL from './country-flags/netherlands-flag-country-nation-union-empire-33035.svg'
import UK from './country-flags/united-kingdom-flag-country-nation-union-empire-33115.svg'
import SG from './country-flags/singapore-flag-country-nation-union-empire-33080.svg'

export const IMAGES = { AU, CH, DE, FR, IT, IE, NL, UK, SG }
export default ({ country, width, height, style, ...props }) => {
  if (!width && !height) {
    width = 100
    height = 75
  } else {
    width = !width && height ? height / 0.75 : width
    height = !height && width ? width * 0.75 : height
  }

  if (!country) {
    return null
  }

  return <img src={IMAGES[country.toUpperCase()]} {...props} style={{ width, height, ...(style || {}) }} />
}
