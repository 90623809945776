import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '../../../utils/styled'
import {
  FormControl as UnstyledFormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery
} from '@mui/material'
import semverCompare from '../../../utils/semver_compare'
import isPrerelease from '../../../utils/is_prerelease'
import uniq from 'lodash/uniq'
import Apple from 'mdi-material-ui/Apple'
import Android from 'mdi-material-ui/Android'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import PlatformButton from './platform_button'
import Instructions from './instructions'
import useQueryParams from '../../../utils/use_query_params'
import { useNavigate, useLocation } from 'react-router'
import { useTheme } from '@mui/material/styles'

const Container = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(2)};
`

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing(2)};
  width: 100%;
  flex: 1;

  ${(props) =>
    props.$mobileLayout &&
    `
      flex-direction: column;
      gap: ${props.theme.spacing(1)};
      align-items: stretch;
    `};
`

const FormControl = styled(UnstyledFormControl).attrs(() => ({
  variant: 'standard',
  size: 'small'
}))`
  flex: 1;
  max-width: 100%;
`

const PlatformButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  gap: ${(props) => props.theme.spacing(2)};
  margin: ${(props) => props.theme.spacing(1, 0, 2, 0)};
  flex-wrap: wrap;
`

const FormField = ({ label, value, onChange, options }) => {
  const normalizedOptions =
    options.length && typeof options[0] === 'string'
      ? options.map((option) => ({ label: option, value: option }))
      : options

  return (
    <FormControl focused={!!value}>
      <InputLabel>{label}</InputLabel>
      <Select
        defaultValue={value}
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
        }}
        displayEmpty
      >
        {normalizedOptions.map(({ label, value }) => (
          <MenuItem key={`${label}-${value}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const dateCompare = (a, b) => Date.parse(b) - Date.parse(a)

const extract = (builds, filters, key, skipPrereleases = false) => {
  const filteredList = builds.filter((build) => {
    return Object.entries(filters).every(([filterKey, filterValue]) => build[filterKey] === filterValue)
  })

  if (skipPrereleases) {
    const withoutPreReleases = uniq(
      filteredList
        .filter((build) => !isPrerelease(build.runtimeVersion) && !isPrerelease(build.version))
        .map((build) => build[key])
    )

    if (withoutPreReleases.length > 0) {
      return withoutPreReleases
    }
  }

  return uniq(filteredList.map((build) => build[key]))
}

const isRealiOSDevice = () =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

const isRealAndroidDevice = () => navigator.userAgent.includes('Android')

const App = ({ appName, builds }) => {
  const params = useQueryParams()
  const platform = params.get('platform')
  const environment = params.get('environment')
  const runtimeVersion = params.get('runtimeVersion')
  const version = params.get('version')
  const gitRevision = params.get('gitRevision')
  const completedAt = params.get('completedAt')
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const mobileLayout = useMediaQuery(theme.breakpoints.down('md'))
  const isRealDevice = isRealiOSDevice() || isRealAndroidDevice()

  const setQueryParams = (params) => {
    // convert params to query params
    const currentParams = new URLSearchParams(location.search).toString()
    const queryParams = new URLSearchParams(params).toString()
    if (currentParams !== queryParams) {
      const url = `${location.pathname}?${queryParams}`
      navigate(url)
    }
  }

  // Importance sort: platform, environment, runtimeVersion, version, gitRevision
  const availablePlatforms = extract(builds, {}, 'platform')
  const availableEnvironments = extract(builds, { platform }, 'environment')
  const availableRuntimeVersions = extract(builds, { platform, environment }, 'runtimeVersion')
    .sort(semverCompare)
    .reverse()
  const availableVersions = extract(builds, { platform, environment, runtimeVersion }, 'version')
    .sort(semverCompare)
    .reverse()
  const availableGitRevisions = extract(builds, { platform, environment, runtimeVersion, version }, 'gitRevision')
  const availableCompletedAt = extract(
    builds,
    { platform, environment, runtimeVersion, version, gitRevision },
    'completedAt'
  )
    .sort(dateCompare)
    .reverse()

  const setValues = (newPlatform, newEnvironment, newRuntimeVersion, newVersion, newGitRevision, newCompletedAt) => {
    const newEnvironmentValue = newEnvironment || extract(builds, { platform: newPlatform }, 'environment', true)[0]

    const newRuntimeVersionValue =
      newRuntimeVersion ||
      extract(builds, { platform: newPlatform, environment: newEnvironmentValue }, 'runtimeVersion', true)
        .sort(semverCompare)
        .reverse()[0]

    const newVersionValue =
      newVersion ||
      extract(
        builds,
        { platform: newPlatform, environment: newEnvironmentValue, runtimeVersion: newRuntimeVersionValue },
        'version',
        true
      )
        .sort(semverCompare)
        .reverse()[0]

    const newGitRevisionValue =
      newGitRevision ||
      extract(
        builds,
        {
          platform: newPlatform,
          environment: newEnvironmentValue,
          runtimeVersion: newRuntimeVersionValue,
          version: newVersionValue
        },
        'gitRevision',
        true
      )[0]

    const newCompletedAtValue =
      newCompletedAt ||
      extract(
        builds,
        {
          platform: newPlatform,
          environment: newEnvironmentValue,
          runtimeVersion: newRuntimeVersionValue,
          version: newVersionValue,
          gitRevision: newGitRevisionValue
        },
        'completedAt',
        true
      )[0]

    setQueryParams({
      platform: newPlatform,
      environment: newEnvironmentValue,
      runtimeVersion: newRuntimeVersionValue,
      version: newVersionValue,
      gitRevision: newGitRevisionValue,
      completedAt: newCompletedAtValue
    })
  }

  const selectedBuild = builds.find(
    (build) =>
      build.platform === platform &&
      build.environment === environment &&
      build.runtimeVersion === runtimeVersion &&
      build.version === version &&
      build.gitRevision === gitRevision
  )

  useEffect(() => {
    if (builds && builds.length > 0 && !selectedBuild) {
      const defaultPlatform = isRealAndroidDevice() ? 'android' : 'ios'
      setValues(defaultPlatform, 'testing')
    }
  }, [appName, builds.length, selectedBuild])

  return (
    <Container>
      <Instructions
        build={selectedBuild}
        key={selectedBuild?.id}
        environment={environment}
        platform={platform}
        isRealDevice={isRealDevice}
      />

      {!isRealDevice && (
        <>
          <Typography variant='subtitle1' sx={{ mt: 2 }}>
            Select one of the presets
          </Typography>

          <PlatformButtons>
            <PlatformButton
              icon={Apple}
              label='iPhone'
              onClick={() => setValues('ios', 'testing')}
              selected={platform === 'ios' && environment !== 'development'}
            />
            {!mobileLayout && (
              <PlatformButton
                icon={Apple}
                label='iPhone Simulator'
                onClick={() => setValues('ios', 'development')}
                selected={platform === 'ios' && environment === 'development'}
              />
            )}
            <PlatformButton
              icon={Android}
              label='Android'
              onClick={() => setValues('android', 'testing')}
              selected={platform === 'android' && environment !== 'development'}
            />
            {!mobileLayout && (
              <PlatformButton
                icon={Android}
                label='Android Simulator'
                onClick={() => setValues('android', 'development')}
                selected={platform === 'android' && environment === 'development'}
              />
            )}
          </PlatformButtons>
        </>
      )}

      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Typography>Select specific version</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormContainer $mobileLayout={mobileLayout}>
            <FormField
              label='Platform'
              value={platform || ''}
              onChange={(value) => setValues(value)}
              options={availablePlatforms}
            />

            <FormField
              label='Environment'
              value={environment || ''}
              onChange={(value) => setValues(platform, value)}
              options={availableEnvironments}
            />

            <FormField
              label='Runtime Version'
              value={runtimeVersion || ''}
              onChange={(value) => setValues(platform, environment, value)}
              options={availableRuntimeVersions}
            />

            <FormField
              label='Version'
              value={version || ''}
              onChange={(value) => setValues(platform, environment, runtimeVersion, value)}
              options={availableVersions}
            />

            <FormField
              label='Git Revision'
              value={gitRevision || ''}
              onChange={(value) => setValues(platform, environment, runtimeVersion, version, value)}
              options={availableGitRevisions.map((revision) => ({ label: revision.slice(0, 7), value: revision }))}
            />

            <FormField
              label='Build time'
              value={completedAt || ''}
              onChange={(value) => setValues(platform, environment, runtimeVersion, version, value, completedAt)}
              options={availableCompletedAt}
            />
          </FormContainer>
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}

App.propTypes = {
  builds: PropTypes.arrayOf(
    PropTypes.shape({
      appName: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
      runtimeVersion: PropTypes.string.isRequired,
      platform: PropTypes.oneOf(['ios', 'android']).isRequired,
      environment: PropTypes.string.isRequired,
      gitRevision: PropTypes.string.isRequired,
      buildUrl: PropTypes.string.isRequired
    })
  )
}

export default App
