import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Box, TextField, MenuItem } from '@mui/material'
import add from 'date-fns/add'
import startOfDay from 'date-fns/startOfDay'
import startOfTomorrow from 'date-fns/startOfTomorrow'
import nextMonday from 'date-fns/nextMonday'
import format from 'date-fns/format'
import styled from '../utils/styled'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(2)};
`

const ReservationForm = ({ activeUntil, setActiveUntil, label, setLabel, single = true, ...props }) => {
  const [customDate, setCustomDate] = useState(null)
  const now = useRef(new Date()).current

  const oneHour = add(now, { hours: 1 })
  const twoHours = add(now, { hours: 2 })
  const threeHours = add(now, { hours: 3 })
  const sixHours = add(now, { hours: 6 })
  const tomorrow = startOfTomorrow(now)
  const monday = startOfDay(nextMonday(now))

  useEffect(() => {
    // set default value from form
    if (activeUntil === null) {
      setActiveUntil(oneHour)
    }
  }, [])

  const onChange = ({ target: { value } }) => {
    if (value === 'custom-date') {
      setCustomDate(now)
    } else {
      setCustomDate(null)
      setActiveUntil(value)
    }
  }

  const onDatePickerChange = (value) => {
    setCustomDate(value)
    setActiveUntil(value)
  }

  const textFieldValue = customDate ? 'custom-date' : Number(activeUntil)

  return (
    <Container {...props}>
      <TextField
        select
        label={
          single ? 'How long do you want to reserve the instance?' : 'How long do you want to reserve the instances?'
        }
        value={textFieldValue}
        onChange={onChange}
      >
        <MenuItem value={Number(oneHour)}>1 hour ({format(oneHour, 'p')})</MenuItem>
        <MenuItem value={Number(twoHours)}>2 hours ({format(twoHours, 'p')})</MenuItem>
        <MenuItem value={Number(threeHours)}>3 hours ({format(threeHours, 'p')})</MenuItem>
        <MenuItem value={Number(sixHours)}>6 hours ({format(sixHours, 'p')})</MenuItem>
        <MenuItem value={Number(tomorrow)}>Until tomorrow ({format(tomorrow, 'MMM do')})</MenuItem>
        <MenuItem value={Number(monday)}>Until Monday ({format(monday, 'MMM do')})</MenuItem>
        <MenuItem value='custom-date'>Choose date and time</MenuItem>
      </TextField>

      {!!customDate && (
        <DateTimePicker
          value={customDate}
          onChange={onDatePickerChange}
          renderInput={(params) => <TextField {...params} />}
          ampm={false}
          disablePast
          inputFormat='dd/MM/yyyy HH:mm'
        />
      )}

      <TextField placeholder='Reservation reason (optional)' value={label} onChange={(e) => setLabel(e.target.value)} />
    </Container>
  )
}

ReservationForm.propTypes = {
  activeUntil: PropTypes.number,
  setActiveUntil: PropTypes.func.isRequired,
  single: PropTypes.bool
}

export default observer(ReservationForm)
