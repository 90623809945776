import React from 'react'
import styled from '../../../utils/styled'
import { Plus, Home, CloudUpload, HardDrive, Smartphone } from 'lucide-mui'
import { Link, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

const Container = styled('div')`
  height: 48px;
  width: 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.palette.background.default};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Actions = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  max-width: 500px;
`

const ButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)};

  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.spacing(4)};

  ${({ $isActive }) => $isActive && 'background: #fff;'};
`

const Button = ({ to, active, icon: Icon, children, ...props }) => {
  const { pathname } = useLocation()
  const isActive = active
    ? typeof active === 'string'
        ? pathname === active
        : active.test(pathname)
    : pathname.startsWith(to)

  return (
    <ButtonContainer to={to} {...props} $isActive={isActive}>
      <Icon />
    </ButtonContainer>
  )
}

export default observer(() => {
  return (
    <Container>
      <Actions>
        <Button icon={Home} label='Dashboard' to='/' active='/' />
        <Button icon={CloudUpload} label='Deployments' to='/list' active={/^\/(list|build)/} />
        <Button icon={Plus} label='New deployment' to='/create' />
        <Button icon={HardDrive} label='Testing instances' to='/instances' />
        <Button icon={Smartphone} label='Mobile apps' to='/apps' />
      </Actions>
    </Container>
  )
})
