import React from 'react'
import { Button } from '@mui/material'
import CalendarCheck from 'mdi-material-ui/CalendarCheck'
import CalendarRemove from 'mdi-material-ui/CalendarRemove'
import { useStore } from '../../../store'
import useReserve from './use_reserve'
import Dialog from './dialog'

const ReserveButton = ({ name, activeReservation, ...props }) => {
  const {
    session: {
      currentUser: { email }
    }
  } = useStore()
  const { dialogProps, triggerProps } = useReserve({ name, activeReservation })

  const reservedByCurrentUser = activeReservation?.username === email
  const icon = reservedByCurrentUser ? <CalendarRemove /> : <CalendarCheck />
  const label = reservedByCurrentUser ? 'Release' : 'Reserve'

  return (
    <>
      <Button variant='outlined' color='info' startIcon={icon} {...triggerProps} {...props}>
        {label}
      </Button>
      <Dialog {...dialogProps} />
    </>
  )
}

export default ReserveButton
