import React from 'react'
import styled from '../utils/styled'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const UnstyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)`
  & .${tooltipClasses.tooltip} {
    background: transparent;
    border: 0;
    max-width: 350px;
  }
`
export default UnstyledTooltip
