import { Paper, Skeleton } from '@mui/material'
import React from 'react'
import styled from '../../../utils/styled'

const FakeInstanceContainer = styled(Paper)`
  padding: ${(props) => props.theme.spacing(1)};
  height: 56px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
`

const SkeletonGroup = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SkeletonVertical = styled('div')`
  display: flex;
  flex-direction: column;
`

const FakeInstance = ({ noExtraLabel, ...props }) => (
  <FakeInstanceContainer {...props}>
    <SkeletonGroup sx={{ flex: 1 }}>
      <SkeletonGroup>
        <Skeleton sx={{ width: 40, height: 33, mr: 2, ml: 0.2, transform: 'scale(1)' }} />
        <SkeletonVertical>
          <Skeleton sx={{ width: 80, mr: 1 }} />
          <Skeleton sx={{ width: 120, height: 14 }} />
        </SkeletonVertical>
      </SkeletonGroup>
      <SkeletonGroup>
        {!noExtraLabel && <Skeleton sx={{ width: 40, mr: 1 }} />}
        <Skeleton sx={{ width: 70, mr: 1 }} />
      </SkeletonGroup>
    </SkeletonGroup>
  </FakeInstanceContainer>
)

export default FakeInstance
