import React from 'react'
import { observer } from 'mobx-react'
import { useQuery } from '@apollo/client'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  Typography
} from '@mui/material'
import Close from 'mdi-material-ui/Close'
import { BACKEND, WEB, WEB_NATIVE, NATIVE } from '../../../constants/app_types'
import { FETCH_REPOSITORIES } from '../../../api/github'
import styled from '../../../utils/styled'
import Input from './input'
import SimplifiedCard from '../../simplified_card'
import { GREEN_SAGE_TEA } from '../../../constants/brand'
import BeatLoader from 'react-spinners/BeatLoader'

const Container = styled('div')`
  position: relative;
`

const DisableOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.palette.background.default};
  opacity: 0.5;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CleanTableRow = styled(TableRow)`
  & > * {
    border-bottom: unset;
  }
`

const AppInput = observer(({ appModel, autocompleteOptions, deployment }) => (
  <Input
    app={appModel}
    options={autocompleteOptions[appModel.app]}
    placeholder={appModel.appType === BACKEND ? 'Branch name or git commit revision' : 'Branch name'}
    value={appModel.appType === BACKEND ? appModel.revision : appModel.branch}
    onChange={(value) => appModel.update(value)}
  />
))

const AppRow = observer(({ appModel, autocompleteOptions, deployment }) => (
  <CleanTableRow>
    <TableCell sx={{ whiteSpace: 'nowrap', verticalAlign: 'top', paddingTop: 3.5 }}>
      {appModel.app} {appModel.appType}
    </TableCell>
    <TableCell sx={{ width: '100%', paddingRight: 0 }}>
      <AppInput appModel={appModel} autocompleteOptions={autocompleteOptions} deployment={deployment} />
    </TableCell>
    <TableCell padding='checkbox' sx={{ verticalAlign: 'top', paddingTop: 2.15 }}>
      <IconButton onClick={() => deployment.removeApp(appModel)}>
        <Close />
      </IconButton>
    </TableCell>
  </CleanTableRow>
))

const AppRowCondensed = observer(({ appModel, autocompleteOptions, deployment }) => (
  <CleanTableRow>
    <TableCell sx={{ whiteSpace: 'nowrap', verticalAlign: 'top', p: 0, pt: 1, pb: 1 }}>
      <Typography variant='subtitle1' sx={{ mb: 2 }}>
        {appModel.app} {appModel.appType}
      </Typography>
      <AppInput appModel={appModel} autocompleteOptions={autocompleteOptions} deployment={deployment} />
    </TableCell>
    <TableCell padding='checkbox' sx={{ verticalAlign: 'top', paddingTop: 0.25 }}>
      <IconButton onClick={() => deployment.removeApp(appModel)}>
        <Close />
      </IconButton>
    </TableCell>
  </CleanTableRow>
))

const Apps = ({ apps, deployment, condensed = false, disabled = false }) => {
  const allApps = Object.entries(apps).reduce((list, [app, { type }]) => {
    if (type === WEB_NATIVE) {
      list.push({ app, appType: WEB })
      list.push({ app, appType: NATIVE })
    } else if (type === WEB) {
      list.push({ app, appType: WEB })
    } else if (type === NATIVE) {
      list.push({ app, appType: NATIVE })
    } else {
      list.push({ app, appType: BACKEND })
    }
    return list
  }, [])

  const availableToAdd = allApps.filter(({ app, appType }) => {
    return !deployment.apps.some((appModel) => appModel.appType === appType && appModel.app === app)
  })

  const onNewAppSelect = (e) => {
    const { app, appType } = e.target.value

    deployment.addApp(appType, app)
  }

  const { data } = useQuery(FETCH_REPOSITORIES)
  const autocompleteOptions = data
    ? data.repositories.reduce((acc, repo) => {
        acc[repo.name] = repo.branches
        return acc
      }, {})
    : {}

  const AppRowComponent = condensed ? AppRowCondensed : AppRow

  return (
    <Container>
      {disabled && (
        <DisableOverlay>
          <BeatLoader color={GREEN_SAGE_TEA} />
        </DisableOverlay>
      )}
      {deployment.apps.length > 0 && (
        <SimplifiedCard title='Applications to deploy' sx={{ mb: 3 }} noExtraBottomPadding>
          <TableContainer>
            <Table>
              <TableBody>
                {deployment.apps.map((appModel) => (
                  <AppRowComponent
                    appModel={appModel}
                    deployment={deployment}
                    autocompleteOptions={autocompleteOptions}
                    key={`${appModel.app}.${appModel.appType}`}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </SimplifiedCard>
      )}

      {availableToAdd.length > 0 && (
        <SimplifiedCard title='Add new application' sx={{ mb: 2 }}>
          <FormControl sx={{ width: '100%', mt: 1 }}>
            <InputLabel>Select an app to add it to the list</InputLabel>
            <Select onChange={onNewAppSelect} value='' label='Select an app to add it to the list' disabled={disabled}>
              {availableToAdd.map(({ app, appType }) => (
                <MenuItem key={`${app}.${appType}`} value={{ app, appType }}>
                  {app} ({appType})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </SimplifiedCard>
      )}
    </Container>
  )
}

export default observer(Apps)
