import React from 'react'
import uniq from 'lodash/uniq'
import { GREEN_GROWN_UP_GREEN } from '../../../../constants/brand'
import { Dot, SubmenuItem, SubmenuIcon, SubmenuLink, Submenu } from './_components'

import { FETCH_NATIVE_APP_BUILDS } from '../../../../api/native_apps'
import { useQuery } from '../../../../utils/apollo'
import { Skeleton } from '@mui/material'
import { useIsActiveUrl } from '../../../../utils/use_is_active_url'

const SidebarSubmenuApps = () => {
  const { data, loading } = useQuery(FETCH_NATIVE_APP_BUILDS, {
    fetchPolicy: 'cache-only'
  })
  const apps = uniq((data?.nativeAppBuilds || []).map((build) => build.appName)).sort()
  const isActiveURL = useIsActiveUrl()

  return (
    <Submenu $empty={apps.length === 0}>
      {loading &&
        new Array(3).fill(0).map((_, i) => (
          <SubmenuItem key={i}>
            <SubmenuIcon>
              <Dot color={GREEN_GROWN_UP_GREEN} size={6} />
            </SubmenuIcon>
            <Skeleton variant='text' width='60%' />
          </SubmenuItem>
        ))}
      {apps.map((appName) => (
        <SubmenuItem key={appName} $isActive={isActiveURL(`/apps/${appName}`)}>
          <SubmenuIcon>
            <Dot color={GREEN_GROWN_UP_GREEN} size={6} />
          </SubmenuIcon>
          <SubmenuLink to={`/apps/${appName}`}>{appName}</SubmenuLink>
        </SubmenuItem>
      ))}
    </Submenu>
  )
}

export default SidebarSubmenuApps
