import { gql } from '@apollo/client'

export const FETCH_NATIVE_APP_BUILDS = gql`
  query nativeAppBuilds {
    nativeAppBuilds {
      appName
      id
      projectId
      version
      runtimeVersion
      platform
      environment
      gitRevision
      artifactUrl
      buildUrl
      completedAt
    }
  }
`
