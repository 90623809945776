import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableRow as UnstyledTableRow,
  TableCell as UnstyledTableCell
} from '@mui/material'
import { TEAL_1 } from '../../../../constants/brand'
import SimplifiedCard from '../../../simplified_card'
import parseISO from 'date-fns/parseISO'
import styled from '../../../../utils/styled'
import { BACKEND, NATIVE, WEB } from '../../../../constants/app_types'
import Branch from '../../instances/branch'
import Revision from '../../../revision'
import relativeDate from 'tiny-relative-date'
import { Globe, HardDrive, Smartphone } from 'lucide-mui'

const TableRow = styled(UnstyledTableRow)`
  ${(props) =>
    !props.withBottomBorder &&
    `
    &:last-child td, &:last-child th {
      border: 0
    }
  `}
`

const TableCell = styled(UnstyledTableCell)`
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ${(props) =>
    props.$collapse &&
    `
    padding-left: 0;
    padding-right: 0;
    width: 0;
    white-space: pre;
  `}
`

const AppVersion = styled('div')`
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  padding: ${(props) => props.theme.spacing(0.5, 0)};
  color: ${TEAL_1};

  a {
    color: ${TEAL_1};
    text-decoration: none;
  }
`

const AppIcon = ({ appType, ...props }) => {
  if (appType === BACKEND) {
    return <HardDrive {...props} />
  }
  if (appType === WEB) {
    return <Globe {...props} />
  }
  if (appType === NATIVE) {
    return <Smartphone {...props} />
  }
  return null
}

const AppVersionsCard = ({ apps }) => (
  <SimplifiedCard title='App versions' noExtraBottomPadding>
    <Table size='small' sx={{ mt: -1.5 }}>
      <TableBody>
        {Object.entries(apps).map(([app, versions]) => (
          <TableRow key={app}>
            <TableCell>
              <Typography variant='body1' sx={{ pb: 0.5, pt: 0.5 }}>
                {app}
              </Typography>

              {versions.map(({ appType, appUrl, revision, branch, updatedAt }) => (
                <AppVersion key={appType}>
                  <AppIcon appType={appType} sx={{ width: 20, height: 20, mr: 1 }} />
                  <Typography fontSize='inherit' color='textSecondary' sx={{ flex: 1 }}>
                    {!!revision && <Revision app={app} revision={revision} label={branch} />}
                    {!revision && branch && <Branch app={app} branch={branch} />}
                  </Typography>
                  <Typography
                    fontSize='inherit'
                    color='textSecondary'
                    title={new Date(updatedAt).toLocaleString()}
                    noWrap
                  >
                    {relativeDate(parseISO(updatedAt))}
                  </Typography>
                </AppVersion>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </SimplifiedCard>
)

export default AppVersionsCard
