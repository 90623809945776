import { createContext, useContext } from 'react'
import { types } from 'mobx-state-tree'
import { CurrentUser, User } from './models'

const SessionStore = types
  .model('SessionStore', {
    loaded: types.optional(types.boolean, false),
    currentUser: types.maybeNull(CurrentUser),
    oldJenkinsUsername: types.optional(types.maybeNull(types.string), window.localStorage.getItem('jenkins_username'))
  })
  .views((self) => ({
    get isSignedIn () {
      return !!self.currentUser
    },

    get migrationFromTokens () {
      return !!self.oldJenkinsUsername
    }
  }))
  .actions((self) => ({
    setCurrentUser (user) {
      self.currentUser = user
      self.loaded = true
    },
    clearMigrationFromTokens () {
      self.oldJenkinsUsername = null
      window.localStorage.removeItem('semaphore_token')
      window.localStorage.removeItem('jenkins_username')
      window.localStorage.removeItem('jenkins_token')
    }
  }))

const UiStore = types
  .model('UI', {
    title: types.maybeNull(types.string),
    appbarColor: types.maybe(types.string),
    additionalEffectColor: types.maybe(types.string),
    extraBackgroundHeight: types.maybe(types.number)
  })
  .actions((self) => ({
    setTitle (title) {
      self.title = title
    },
    setColors (colors = []) {
      self.appbarColor = colors[0]
      self.additionalEffectColor = colors[1]
    },
    setExtraBackgroundHeight (height) {
      self.extraBackgroundHeight = height
    }
  }))

const UsersStore = types
  .model('Users', {
    users: types.map(User)
  })
  .actions((self) => ({
    load: (users) => !!users && users.forEach((user) => self.users.put(user)),
    get: (email) => self.users.get(email)
  }))

const Store = types.model('Store', {
  session: types.optional(SessionStore, () => SessionStore.create()),
  ui: types.optional(UiStore, () => UiStore.create()),
  users: types.optional(UsersStore, () => UsersStore.create())
})

const store = Store.create()
export const StoreContext = createContext(store)
export const useStore = () => useContext(StoreContext)

export default store
