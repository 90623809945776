import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, Chip, Typography, Button } from '@mui/material'
import styled from '../../../../utils/styled'
import isPrerelease from '../../../../utils/is_prerelease'

const Header = styled(Typography).attrs(() => ({ variant: 'h6', align: 'center' }))`
  ${(props) => props.$blur && 'filter: blur(5px);'}
`

const Container = styled(Paper).attrs(() => ({ elevation: 1 }))`
  margin-top: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(2)};
  min-height: 355px;
  position: relative;

  ${(props) => props.theme.breakpoints.down('md')} {
    min-height: 250px;
    padding-top: ${(props) => props.theme.spacing(6)};
  }
`

const VersionContainer = styled(Box)`
  position: absolute;
  right: ${(props) => props.theme.spacing(2)};
  top: ${(props) => props.theme.spacing(2)};

  ${(props) =>
    props.$condensed &&
    `
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const VersionChip = styled(Chip).attrs(() => ({ size: 'small' }))``

const Instructions = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.$condensed ? '100px' : '200px')};
  position: relative;
  ${(props) => props.$blur && 'filter: blur(5px);'}
`

const Footer = styled(Typography).attrs(() => ({ variant: 'body2', align: 'center', paragraph: true }))`
  ${(props) => props.$blur && 'filter: blur(5px);'}
`

const PreReleaseContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PreReleaseWarning = styled(Box)`
  max-width: 400px;
  background-color: rgba(210, 95, 102, 1);
  color: ${(props) => props.theme.palette.error.contrastText};
  padding: ${(props) => props.theme.spacing(4)};
  text-align: center;
`

const Version = ({ version, runtimeVersion, condensed }) => {
  if (!version || !runtimeVersion) {
    return null
  }

  const color = isPrerelease(runtimeVersion) ? 'error' : 'default'

  if (version === runtimeVersion) {
    return (
      <VersionContainer $condensed={condensed}>
        <VersionChip label={version} color={color} />
      </VersionContainer>
    )
  }

  return (
    <VersionContainer $condensed={condensed}>
      <VersionChip
        color={color}
        label={
          <>
            {runtimeVersion} | JS: {version}
          </>
        }
      />
    </VersionContainer>
  )
}

const BaseInstructions = ({ header, footer, version, runtimeVersion, condensed = false, children }) => {
  const [acknowledge, setAcknowledge] = useState(!isPrerelease(runtimeVersion))

  return (
    <Container $condensed={condensed}>
      <Version version={version} runtimeVersion={runtimeVersion} condensed={condensed} />
      <Header $blur={!acknowledge}>{header}</Header>
      <Instructions $blur={!acknowledge} $condensed={condensed}>
        {children}
      </Instructions>
      <Footer $blur={!acknowledge}>
        {footer}
        {isPrerelease(runtimeVersion) && (
          <>
            <br />
            <br />
            <strong>Warning:</strong> This is a pre-release version. If you were not asked specifically to install it,
            this is likely not the version you are looking for.
          </>
        )}
      </Footer>

      {!acknowledge && (
        <PreReleaseContainer>
          <PreReleaseWarning>
            <Typography variant='body2' align='center' paragraph>
              <strong>Warning:</strong> This is a pre-release version.
              <br />
              <br />
              If you were not asked specifically to install it, this is likely not the version you are looking for.
            </Typography>
            <Button variant='outlined' color='inherit' onClick={() => setAcknowledge(true)}>
              I know what I'm doing
            </Button>
          </PreReleaseWarning>
        </PreReleaseContainer>
      )}
    </Container>
  )
}

BaseInstructions.propTypes = {
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  version: PropTypes.string.isRequired,
  runtimeVersion: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default BaseInstructions
