import React from 'react'
import { Badge, Paper, Tooltip } from '@mui/material'
import CountryIcon from '../country_icon'
import styled from '../../utils/styled'
import statusColor from '../../utils/status_color'

const CountryIconContainer = styled(Paper)`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (props.elevation === 0 ? 'background: transparent;' : '')}
`

const Status = styled(Paper)`
  border-radius: 100%;
  width: 16px;
  height: 16px;
  border: 3px solid #fff;
  background-color: ${(props) => statusColor(props.status, props.theme.palette)};
`

const Avatar = ({ status, country, elevation, size = 40, sx, iconSx }) =>
  typeof status !== 'undefined'
    ? (
      <Tooltip title={status}>
        <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<Status status={status} />}>
          <CountryIconContainer elevation={elevation} size={size} sx={sx}>
            <CountryIcon width={size} height={size} country={country} sx={iconSx} />
          </CountryIconContainer>
        </Badge>
      </Tooltip>
      )
    : (
      <CountryIconContainer elevation={elevation} sx={sx}>
        <CountryIcon width={size} height={size} country={country} sx={iconSx} />
      </CountryIconContainer>
      )

export default Avatar
