import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import withSideEffect from 'react-side-effect'
import { runInAction } from 'mobx'
import store from '../store'
import error16 from '../images/favicon-error-16x16.png'
import error32 from '../images/favicon-error-32x32.png'
import error64 from '../images/favicon-error-64x64.png'
import generic16 from '../images/favicon-generic-16x16.png'
import generic32 from '../images/favicon-generic-32x32.png'
import generic64 from '../images/favicon-generic-64x64.png'
import success16 from '../images/favicon-success-16x16.png'
import success32 from '../images/favicon-success-32x32.png'
import success64 from '../images/favicon-success-64x64.png'
import warning16 from '../images/favicon-warning-16x16.png'
import warning32 from '../images/favicon-warning-32x32.png'
import warning64 from '../images/favicon-warning-64x64.png'

const FAVICONS = {
  error: {
    16: error16,
    32: error32,
    64: error64
  },
  generic: {
    16: generic16,
    32: generic32,
    64: generic64
  },
  success: {
    16: success16,
    32: success32,
    64: success64
  },
  warning: {
    16: warning16,
    32: warning32,
    64: warning64
  }
}

// react-helmet does not support Fragments
const Title = ({ title, favicon = 'generic' }) => {
  return (
    <Helmet titleTemplate='%s - Squirrel' defaultTitle='Squirrel' defer={false}>
      <title>{title}</title>
      {FAVICONS[favicon] && <link rel='icon' type='image/png' href={FAVICONS[favicon][16]} sizes='16x16' />}
      {FAVICONS[favicon] && <link rel='icon' type='image/png' href={FAVICONS[favicon][32]} sizes='32x32' />}
      {FAVICONS[favicon] && <link rel='icon' type='image/png' href={FAVICONS[favicon][64]} sizes='96x96' />}
    </Helmet>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  colors: PropTypes.array,
  extraBackgroundHeight: PropTypes.number
}

const reducePropsToState = (props) => props.reduce((allValues, values) => Object.assign(allValues, values), {})

const handleStateChangeOnClient = ({ title, colors, extraBackgroundHeight }) => {
  runInAction(() => {
    store.ui.setTitle(title)
    store.ui.setColors(colors)
    store.ui.setExtraBackgroundHeight(extraBackgroundHeight)
  })
}

export default withSideEffect(reducePropsToState, handleStateChangeOnClient)(Title)
