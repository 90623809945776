import { useLocation } from 'react-router-dom'

export const useIsActiveUrl = () => {
  const { pathname } = useLocation()

  return (to, activeUrlOrRegex) => {
    if (activeUrlOrRegex) {
      return typeof activeUrlOrRegex === 'string' ? pathname === activeUrlOrRegex : activeUrlOrRegex.test(pathname)
    }

    return pathname.startsWith(to)
  }
}
