import React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import { useTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import styled from '../../utils/styled'
import { useMediaQuery } from '@mui/material'
import Title from '../title'
import Sidebar from './sidebar'
import MobileHeader from './mobile/header'
import MobileNavigation from './mobile/navigation'

const GlobalStyles = createGlobalStyle`
  html {
    min-height: 100vh;
  }
  
  body {
    display: flex;
    flex: 1;
    min-height: 100vh;
    transition: background 0.15s linear;
  }
  
  #root {
    display: flex;
    flex: 1;
    min-height: 100vh;
  }
`

const Container = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: ${(props) => (props.$mobile ? 'column' : 'row')};
  flex: 1;
  align-items: stretch;
  justify-content: stretch;
`

const ContentContainer = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing(2)} 0;
  overflow-y: auto;
`

const AppContainer = ({ children }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Title title={null} />
      <Container $mobile={mobile}>
        {!mobile && <Sidebar />}
        {mobile && <MobileHeader />}
        <ContentContainer>
          <CssBaseline />
          {children}
        </ContentContainer>
        {mobile && <MobileNavigation />}
      </Container>
      <GlobalStyles />
    </>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node
}

export default AppContainer
