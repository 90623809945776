import React, { useEffect } from 'react'
import { Container, Grid, IconButton, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { useNavigate } from 'react-router'
import Title from '../../title'
import ChevronLeft from 'mdi-material-ui/ChevronLeft'
import ChevronRight from 'mdi-material-ui/ChevronRight'
import styled from '../../../utils/styled'
import { useStore } from '../../../store'
import useQueryParams from '../../../utils/use_query_params'
import { useLazyQuery } from '@apollo/client'
import { FETCH_DEPLOYMENTS } from '../../../api/deployments'
import SimplifiedCard from '../../simplified_card'
import { CRIMSON_1 } from '../../../constants/brand'
import DeploymentsList from './deployments_list'

const ListPageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

const ListPage = () => {
  const [fetchDeployments, { loading, data }] = useLazyQuery(FETCH_DEPLOYMENTS)

  const navigate = useNavigate()
  const store = useStore()
  const query = useQueryParams()
  const page = query.get('page') || 1
  const filter = query.get('filter') !== null ? query.get('filter') : 'my'

  const setPage = (page) => navigate(`/list?page=${page}&filter=${filter}`)
  const setFilter = (filter) => navigate(`/list?page=1&filter=${filter}`)

  const deployments = data?.deployments?.nodes
  const isFirstPage = !data?.deployments?.pageInfo?.hasPreviousPage
  const isLastPage = !data?.deployments?.pageInfo?.hasNextPage

  useEffect(() => {
    const username = filter === 'my' ? store.session.currentUser.email : null
    fetchDeployments({ variables: { username, page: Number(page), limit: 25 } })
  }, [page, filter])

  return (
    <ListPageContainer>
      <Title title='Deployments' colors={[CRIMSON_1, CRIMSON_1]} />

      <SimplifiedCard noExtraBottomPadding sx={{ marginBottom: 3 }}>
        <Grid container direction='row'>
          <FormControl component='fieldset'>
            <RadioGroup name='filter' value={filter} onChange={(e, value) => setFilter(value)} row>
              <FormControlLabel value='my' control={<Radio />} label='Only my deployments' />
              <FormControlLabel value='all' control={<Radio />} label='All deployments' />
            </RadioGroup>
          </FormControl>
        </Grid>
      </SimplifiedCard>

      <DeploymentsList deployments={deployments} loading={loading} expected={25} />

      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <IconButton
            disabled={isFirstPage || loading}
            onClick={() => setPage((page && !isNaN(Number(page)) ? Number(page) : 2) - 1)}
            size='large'
          >
            <ChevronLeft />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            disabled={isLastPage || loading}
            onClick={() => setPage((page && !isNaN(Number(page)) ? Number(page) : 1) + 1)}
            size='large'
          >
            <ChevronRight />
          </IconButton>
        </Grid>
      </Grid>
    </ListPageContainer>
  )
}

export default ListPage
