import React from 'react'
import PropTypes from 'prop-types'
import ChevronDoubleUp from 'mdi-material-ui/ChevronDoubleUp'
import ChevronUp from 'mdi-material-ui/ChevronUp'
import Equal from 'mdi-material-ui/Equal'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import ChevronDoubleDown from 'mdi-material-ui/ChevronDoubleDown'

const COMPONENTS = {
  Highest: ChevronDoubleUp,
  High: ChevronUp,
  Medium: Equal,
  Low: ChevronDown,
  Lowest: ChevronDoubleDown
}

const COLORS = {
  Highest: '#ff5630',
  High: '#ff5630',
  Medium: '#FFAB00',
  Low: '#0065ff',
  Lowest: '#2684ff'
}

const IssuePriority = ({ priority, ...props }) => {
  const Component = COMPONENTS[priority]

  return Component ? <Component htmlColor={COLORS[priority]} {...props} /> : null
}

IssuePriority.propTypes = {
  priority: PropTypes.oneOf(Object.keys(COMPONENTS))
}

export default IssuePriority
