import React from 'react'
import { Box, Chip, CircularProgress } from '@mui/material'
import statusColor from '../../utils/status_color'
import Play from 'mdi-material-ui/Play'
import Pause from 'mdi-material-ui/Pause'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import styled from '../../utils/styled'

const statusIcon = (status, palette) => {
  if (status === 'starting' || status === 'stopping') {
    return (
      <Box sx={{ marginLeft: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={14} color='secondary' />
      </Box>
    )
  }

  const color = statusColor(status, palette)
  const props = { style: { color, fontSize: 18 } }

  if (status === 'running') {
    return <Play {...props} />
  }

  if (status === 'stopped') {
    return <Pause {...props} />
  }

  return <HelpCircleOutline {...props} />
}

const Status = styled(React.memo(({ status, ...props }) => <Chip {...props} />)).attrs(
  ({ status, theme: { palette } }) => ({
    size: 'small',
    variant: 'outlined',
    avatar: statusIcon(status, palette)
  })
)`
  color: ${({ status, theme: { palette } }) => statusColor(status, palette)};
  border-color: ${({ status, theme: { palette } }) => statusColor(status, palette)};

  ${({ label }) =>
    !label &&
    `
    & .MuiChip-label {
      padding-left: 3px;
      padding-right: 3px;
    }
  `}
`

export default Status
