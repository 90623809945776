import React from 'react'
import styled from '../../../utils/styled'
import UnstyledLogo from '../logo'
import { Plus, Home, CloudUpload, HardDrive, Smartphone } from 'lucide-mui'
import Button from './button'
import SidebarSubmenuApps from './submenu/apps'
import SidebarSubmenuDeployments from './submenu/deployments'
import SidebarSubmenuInstances from './submenu/instances'
import { Avatar, CardHeader } from '@mui/material'
import { useStore } from '../../../store'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

const Container = styled('div')`
  width: 340px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.default};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
`

const Logo = styled(UnstyledLogo)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

const Actions = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2)};
  flex: 1;
`

const Separator = styled('div')`
  height: 1px;
  background: #e0e0e0;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`

export default observer(() => {
  const {
    session: {
      currentUser: { name, email, avatarUrl }
    }
  } = useStore()

  return (
    <Container>
      <LogoContainer>
        <Logo /> SQUIRREL
      </LogoContainer>
      <Actions>
        <Button icon={Plus} label='New deployment' to='/create' />
        <Separator />
        <Button icon={Home} label='Dashboard' to='/' active='/' />
        <Button icon={CloudUpload} label='Deployments' to='/list' active={/^\/(list|build)/}>
          <SidebarSubmenuDeployments />
        </Button>
        <Button icon={HardDrive} label='Testing instances' to='/instances'>
          <SidebarSubmenuInstances />
        </Button>
        <Button icon={Smartphone} label='Mobile apps' to='/apps'>
          <SidebarSubmenuApps />
        </Button>
      </Actions>
      <CardHeader avatar={<Avatar src={avatarUrl} />} title={name} subheader={email} sx={{ p: 0 }} />
    </Container>
  )
})
