import { gql } from '@apollo/client'

export const REFRESH_DB_TASKS_FOR_INSTANCE = gql`
  query ($instanceName: String!) {
    refreshDbTasks(instanceName: $instanceName) {
      id
      building
      success
      triggeredAt
      triggeredBy
    }
  }
`

export const REFRESH_DB = gql`
  mutation ($instanceName: String!) {
    refreshDb(instanceName: $instanceName) {
      success
    }
  }
`
