import React from 'react'
import { Typography, List, ListItem, ListItemIcon, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router'
import Check from 'mdi-material-ui/Check'
import ProgressClock from 'mdi-material-ui/ProgressClock'
import AlertOctagon from 'mdi-material-ui/AlertOctagon'
import Help from 'mdi-material-ui/Help'
import styled from '../../../utils/styled'
import uniq from 'lodash/uniq'
import relativeDate from 'tiny-relative-date'
import { FAILURE, IN_PROGRESS, PENDING, SUCCESS } from '../../../constants/statuses'
import Jira from 'mdi-material-ui/Jira'
import { HardDrive } from 'lucide-mui'
import JiraIssue from '../../jira_issue'
import Tooltip from '../../unstyled_tooltip'

const StatusIcon = ({ status, ...props }) => {
  if (status === SUCCESS) {
    return <Check {...props} />
  } else if (status === PENDING || status === IN_PROGRESS) {
    return <ProgressClock {...props} />
  } else if (status === FAILURE) {
    return <AlertOctagon {...props} />
  } else {
    return <Help {...props} />
  }
}

const ListItemLink = ({ href, onClick, ...props }) => {
  const navigate = useNavigate()
  return (
    <ListItem
      button
      component='a'
      {...props}
      href={href}
      onClick={(e) => {
        if (!e.ctrlKey && !e.metaKey) {
          e.preventDefault()
          navigate(href)
        }
        return onClick ? onClick(e) : undefined
      }}
    />
  )
}

const Deployments = styled(List).attrs(() => ({ component: 'nav' }))`
  opacity: ${(props) => (props.loading ? 0.6 : 1)};
`

const DeploymentTitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`
const DeploymentId = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))`
  flex: 1;
`
const DeploymentDate = styled(Typography).attrs(() => ({ variant: 'caption', noWrap: true }))`
  color: ${({ theme }) => theme.palette.text.secondary};
`

const DeploymentDetails = styled('div')`
  flex: 1;
`

const DetailsContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${({ theme, $color }) => $color || theme.palette.text.secondary};
  margin-top: ${({ theme, $small }) => theme.spacing($small ? 0.25 : 0.5)};
  font-size: ${({ theme, $small }) => ($small ? '0.75rem' : '0.875rem')};
`

const Details = ({ icon: Icon, children, small, ...props }) => (
  <DetailsContainer {...props} $small={small}>
    <Icon sx={{ fontSize: small ? 12 : 18, mr: small ? 0.5 : 0.75, mt: 0.2 }} color='inherit' />
    <Typography variant='subtitle2' color='inherit' sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
      {children}
    </Typography>
  </DetailsContainer>
)

const Instances = ({ deployment }) => {
  const instances = uniq(
    deployment.builds.nodes.filter((build) => build?.instance).map((build) => build.instance.name)
  ).map((name) => {
    const match = name.match(/^test-(.*)-([0-9]*)/)
    return match ? `${match[1].toUpperCase()} ${match[2]}` : name
  })

  return <Details icon={HardDrive}>{instances.join(', ')}</Details>
}

const JiraIssuePreview = ({ jiraIssue, last }) => {
  return (
    <Tooltip title={<JiraIssue issue={jiraIssue} noOpenButton />} followCursor>
      <span>
        {jiraIssue.key} {!last && ', '}
      </span>
    </Tooltip>
  )
}

const DeploymentsList = ({ deployments, loading, expected }) => {
  if (loading) {
    return (
      <Deployments>
        {[...Array(expected)].map((_, i) => (
          <ListItem key={i}>
            <ListItemIcon>
              <Skeleton variant='circular' width={24} height={24} />
            </ListItemIcon>
            <DeploymentDetails>
              <DeploymentTitle>
                <DeploymentId>
                  <Skeleton sx={{ width: '100%', maxWidth: '200px', height: 26 }} />
                </DeploymentId>
                <DeploymentDate>
                  <Skeleton sx={{ width: '60px', ml: 2, height: 26 }} />
                </DeploymentDate>
              </DeploymentTitle>
              <Skeleton sx={{ width: '100%', maxWidth: '90px' }} />
              <Skeleton sx={{ width: '100%', maxWidth: '90px' }} />
            </DeploymentDetails>
          </ListItem>
        ))}
      </Deployments>
    )
  }

  if (!deployments) {
    return null
  }

  return (
    <Deployments>
      {deployments.map((deployment) => (
        <ListItemLink href={`/build/${deployment.id}`} key={deployment.id}>
          <ListItemIcon>
            <StatusIcon status={deployment.status} />
          </ListItemIcon>
          <DeploymentDetails>
            <DeploymentTitle>
              <DeploymentId>Deployment #{deployment.id}</DeploymentId>
              <DeploymentDate>
                <Tooltip title={new Date(deployment.updatedAt).toLocaleString()}>
                  <span>{relativeDate(deployment.updatedAt)}</span>
                </Tooltip>
              </DeploymentDate>
            </DeploymentTitle>
            {deployment.jiraIssues.length > 0 && (
              <Details icon={Jira}>
                {deployment.jiraIssues.map((jiraIssue, index) => (
                  <JiraIssuePreview
                    key={jiraIssue.key}
                    jiraIssue={jiraIssue}
                    last={deployment.jiraIssues.length - 1 === index}
                  />
                ))}
              </Details>
            )}
            <Instances deployment={deployment} />
          </DeploymentDetails>
        </ListItemLink>
      ))}
    </Deployments>
  )
}

export default DeploymentsList
