export const UNKNOWN = 'unknown'
export const FAILURE = 'failure'
export const SUCCESS = 'success'
export const IN_PROGRESS = 'in_progress'
export const PENDING = 'pending'

export const INSTANCE_RUNNING = 'running'
export const INSTANCE_STOPPED = 'stopped'
export const INSTANCE_STARTING = 'starting'
export const INSTANCE_STOPPING = 'stopping'
