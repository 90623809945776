// adapter from semver-compare with extra support for -alpha, -beta, -rc
const WEIGHTS = ['alpha', 'beta', 'rc']
export default function cmp (a, b) {
  const pa = a.split('.')
  const pb = b.split('.')
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i])
    const nb = Number(pb[i])
    if (na > nb) return 1
    if (nb > na) return -1
    if (!isNaN(na) && isNaN(nb)) return 1
    if (isNaN(na) && !isNaN(nb)) return -1
  }

  // extract prerelease part (e.g. -alpha, -alpha1, -alpha.1)
  let ppa = a.split('-')[1]
  let ppb = b.split('-')[1]

  // if either of them is not a prerelease, return 0
  if (!ppa && !ppb) return 0

  // assume prerelease is always less than release
  if (!ppa) return 1
  if (!ppb) return -1

  // extract numerical part
  let na = ppa.match(/\d+$/)
  let nb = ppb.match(/\d+$/)

  // remove numerical part from prerelease part
  ppa = ppa.replace(/\.?\d+$/, '')
  ppb = ppb.replace(/\.?\d+$/, '')

  // compare weight of the release
  let wa = WEIGHTS.indexOf(ppa)
  let wb = WEIGHTS.indexOf(ppb)

  // if no weight is found, set it to 3 (higher than any weight)
  wa = wa === -1 ? 3 : Number(wa)
  wb = wb === -1 ? 3 : Number(wb)

  // compare weights
  if (wa > wb) return 1
  if (wb > wa) return -1

  // compare numerical part of prerelease
  // assume alpha is like alpha.0 etc
  na = na ? Number(na[0]) : 0
  nb = nb ? Number(nb[0]) : 0

  if (na > nb) return 1
  if (nb > na) return -1

  return 0
}
