import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import styled from '../utils/styled'
import { Box, Button, Typography, Card, Skeleton, IconButton } from '@mui/material'
import nl2br from 'react-nl2br'
import Jira from 'mdi-material-ui/Jira'
import JiraDetails from './jira_details'
import { useLazyQuery } from '@apollo/client'
import { FETCH_JIRA_ISSUE } from '../api/jira'
import { X } from 'lucide-mui'

const Container = styled(Box)`
  background: #0052cc;
  color: #fff;
  padding: ${(props) => props.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Header = styled(Typography).attrs(() => ({
  color: 'inherit',
  variant: 'subtitle2'
}))`
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  color: #fff;
`

const JiraIcon = styled(Jira)`
  font-size: 20px;
  margin-right: 7px;
  vertical-align: middle;
`

const Key = styled('span')`
  font-weight: 700;
  flex: 1;
`

const Content = styled(Box)`
  padding: ${(props) => props.theme.spacing(2)};
`

const Title = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  display: 'block'
}))`
  margin-bottom: ${(props) => props.theme.spacing(2)};
`

const Description = styled(Typography).attrs(() => ({
  variant: 'body2',
  display: 'block'
}))`
  margin-bottom: ${(props) => props.theme.spacing(2)};
  max-height: ${(props) => props.theme.spacing(15)};
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`

const JiraIssue = ({ id, issue, onRemove, noOpenButton = false, ...props }) => {
  const [fetchIssue, { loading, data }] = useLazyQuery(FETCH_JIRA_ISSUE)

  useEffect(() => {
    if (typeof issue === 'string') {
      fetchIssue({ variables: { key: issue } })
    }
  }, [issue])

  if (!issue || (typeof issue === 'string' && !loading && !data)) {
    return null
  }

  const details = typeof issue === 'string' ? data?.jiraIssue : issue

  return (
    <Card elevation={1} sx={{ mb: 3 }} {...props}>
      <Container>
        <Header>
          <JiraIcon />
          <Key>{details ? details.key : <Skeleton animation='pulse' variant='text' height='26' />}</Key>
          {!noOpenButton && (
            <Button
              disabled={loading}
              variant='outlined'
              size='small'
              component='a'
              href={details ? details.url : null}
              target='_blank'
              color='inherit'
            >
              Open in Jira
            </Button>
          )}
          {!!onRemove && (
            <IconButton onClick={onRemove} size='small' color='inherit' sx={{ marginRight: '-6px', marginLeft: 1 }}>
              <X />
            </IconButton>
          )}
        </Header>
      </Container>
      <Content>
        <Title>{details ? details.title : <Skeleton />}</Title>
        <Description>
          {details
            ? (
                nl2br(details.description)
              )
            : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton width='40%' />
              </>
              )}
        </Description>
        {details
          ? (
            <JiraDetails team={details.team} status={details.status} priority={details.priority} />
            )
          : (
            <Box display='flex' justifyContent='space-between'>
              <Skeleton width='20%' />
              <Skeleton width='30%' />
            </Box>
            )}
      </Content>
    </Card>
  )
}

JiraIssue.propTypes = {
  id: PropTypes.string,
  issue: PropTypes.string
}

export default observer(JiraIssue)
