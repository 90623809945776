import React from 'react'
import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { WHITE } from '../../../../constants/brand'
import SimplifiedCard from '../../../simplified_card'
import styled from '../../../../utils/styled'
import CountryAvatar from '../../../instance/avatar'
import ActionButton from '../../../instance/action/button'
import statusColor from '../../../../utils/status_color'

const StatusCard = styled(SimplifiedCard).attrs(() => ({
  noExtraBottomPadding: true,
  fullColor: true,
  contentStyle: { flex: 1 }
}))`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.025) 100%),
    ${({ status, theme: { palette } }) => statusColor(status, palette)};
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CountryFlagContainer = styled('div')`
  width: 80px;
`

const CountryFlagCircle = styled('div')`
  padding: ${(props) => props.theme.spacing(2)};
  border-radius: 100px;
  background: #fff;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => props.theme.shadows[1]};
`

const StatusCardContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  color: ${WHITE};
  height: 100%;
`

const StatusCardDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StatusCardLine = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  &:first-child {
    margin-bottom: ${(props) => props.theme.spacing(0.75)};
  }
`

const StatusCardActions = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const InstanceStatus = styled('div')`
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  font-weight: 800;
  align-self: center;
  border: 1px solid #fff;
  padding: 4px 8px;
  border-radius: 4px;
  background: #fff;
  color: ${({ theme, $status }) => statusColor($status, theme.palette)};
`

const StatusCardMetadata = styled('div')`
  color: #fff;
  font-size: 14px;
  line-height: 1.25;
`

const StatusCardMetadataItem = styled('div')`
  display: flex;
  align-items: center;
`

const InstanceStatusCard = ({ instance }) => (
  <StatusCard
    status={instance.status}
    actions={
      <>
        <Button
          component={Link}
          to={{ pathname: '/create' }}
          state={{ instances: [{ name: instance.name }] }}
          variant='text'
          color='inherit'
        >
          New Deployment
        </Button>
        <ActionButton color='inherit' variant='text' startIcon={null} name={instance.name} status={instance.status} />
      </>
    }
    fullColor
  >
    <StatusCardContent>
      <CountryFlagContainer>
        <CountryFlagCircle>
          <CountryAvatar country={instance.country} elevation={0} />
        </CountryFlagCircle>
      </CountryFlagContainer>

      <StatusCardDetails>
        <StatusCardLine>
          <Typography variant='h4' color='inherit' sx={{ fontWeight: 700 }}>
            {instance.name}
          </Typography>
          <InstanceStatus $status={instance.status}>{instance.status}</InstanceStatus>
        </StatusCardLine>
        <StatusCardLine>
          <StatusCardMetadata>
            {!!instance.metadata && (
              <>
                <StatusCardMetadataItem>
                  <b>Ruby</b>: {instance.metadata?.rubyVersion}
                </StatusCardMetadataItem>
                <StatusCardMetadataItem>
                  <b>Rails</b>: {instance.metadata?.railsVersion}
                </StatusCardMetadataItem>
              </>
            )}
          </StatusCardMetadata>
        </StatusCardLine>
      </StatusCardDetails>
    </StatusCardContent>
    <StatusCardActions />
  </StatusCard>
)

export default InstanceStatusCard
