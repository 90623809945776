import { gql } from '@apollo/client'

export const FETCH_REPOSITORIES = gql`
  query repositories {
    repositories {
      name
      branches
    }
  }
`
export const REVISION_DETAILS = gql`
  query revision($appName: String!, $branchOrRevision: String!) {
    revision(appName: $appName, branchOrRevision: $branchOrRevision) {
      sha
      author
      message
      url
      source
    }
  }
`
