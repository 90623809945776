import React from 'react'
import { useQuery } from '../../../utils/apollo'
import SimplifiedCard from '../../simplified_card'
import { FETCH_INSTANCES, RESERVE_INSTANCE } from '../../../api/instances'
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import Instance from '../../instance'
import FakeInstance from '../instances/fake_instance'
import styled from '../../../utils/styled'
import CalendarRemove from 'mdi-material-ui/CalendarRemove'
import { useMutation } from '@apollo/client'
import ConfirmButton from '../../confirm_button'
import LoadingButton from '@mui/lab/LoadingButton'

const FakeReservedInstance = styled(FakeInstance).attrs(() => ({ elevation: 0 }))`
  padding: 0;
`

const ReservedInstance = styled(Instance).attrs(() => ({ elevation: 0 }))`
  padding-left: 0;
  padding-right: 0;
  padding-top: ${(props) => props.theme.spacing(1.2)};
  padding-bottom: ${(props) => props.theme.spacing(1.2)};
`

// prettier-ignore
const PopperProps = {
  modifiers: [{ name: 'offset', options: { offset: [0, -10] } }]
}

const ReservedInstances = ({ username }) => {
  const { loading, data } = useQuery(FETCH_INSTANCES, { pollInterval: 2000, variables: { reservedBy: username } })
  const [reserve, { loading: reserving }] = useMutation(RESERVE_INSTANCE, {
    refetchQueries: [FETCH_INSTANCES]
  })

  if (loading) {
    return (
      <SimplifiedCard title='Your reserved instances'>
        <FakeReservedInstance noExtraLabel />
      </SimplifiedCard>
    )
  }

  const instances = data?.instances || []

  const onRelease = async ({ name }) => reserve({ variables: { name, username: null } })
  const onReleaseAll = async () => {
    const promises = instances.map(({ name }) => reserve({ variables: { name, username: null } }))
    return Promise.all(promises)
  }

  return (
    <SimplifiedCard title='Your reserved instances'>
      <Box>
        {instances.map((instance) => (
          <ReservedInstance
            key={instance.name}
            instance={instance}
            elevation={0}
            noExtraLabel
            actionButton={
              <Tooltip title='Release reservation' placement='top' PopperProps={PopperProps}>
                <ConfirmButton
                  Component={IconButton}
                  messageContent={
                    <>
                      Are you sure you want to release <b>{instance.name}</b>?
                    </>
                  }
                  disabled={reserving}
                  color='error'
                  size='small'
                  sx={{ ml: 1 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    onRelease(instance)
                  }}
                >
                  {reserving ? <CircularProgress size={20} /> : <CalendarRemove />}
                </ConfirmButton>
              </Tooltip>
            }
          />
        ))}
        {instances.length === 0 && (
          <Typography variant='body2' color='textSecondary'>
            You don't have any reserved instances
          </Typography>
        )}
      </Box>
      {instances.length > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ConfirmButton
            Component={LoadingButton}
            loading={reserving}
            color='error'
            variant='outlined'
            sx={{ mt: 2 }}
            startIcon={<CalendarRemove />}
            onClick={onReleaseAll}
            messageContent={
              <>
                Are you sure you want to release <b>{instances.length}</b> instances?
              </>
            }
          >
            Release all reservations
          </ConfirmButton>
        </Box>
      )}
    </SimplifiedCard>
  )
}

export default ReservedInstances
