import React from 'react'
import styled from '../../../utils/styled'
import PropTypes from 'prop-types'
import { ButtonBase, Paper, Typography } from '@mui/material'

const PlatformPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(2)};
  width: 100%;
  ${(props) => props.selected && `border: 1px solid ${props.theme.palette.primary.light};`}
`

const PlatformLabel = styled(Typography).attrs(() => ({
  variant: 'overline'
}))`
  display: block;
  text-align: center;
  line-height: 1;
  margin-top: ${(props) => props.theme.spacing(1)};
`

const PlatformButton = ({ icon: Icon, label, onClick, selected }) => {
  return (
    <ButtonBase focusRipple sx={{ flex: 1 }} onClick={onClick}>
      <PlatformPaper elevation={2} selected={selected}>
        {!!Icon && <Icon />}
        <PlatformLabel>{label}</PlatformLabel>
      </PlatformPaper>
    </ButtonBase>
  )
}

PlatformButton.propTypes = {
  icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
}

export default PlatformButton
