import React from 'react'

export const confirmProps = ({ status, name }) => ({
  messageContent: (
    <>
      Are you sure you want to <b>{status === 'running' ? 'stop' : 'start'}</b> {name}?
    </>
  ),
  buttonYesTitle: 'Yes',
  buttonNoTitle: 'No'
})
