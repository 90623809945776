import React from 'react'
import PropTypes from 'prop-types'
import { Box, LinearProgress, Typography } from '@mui/material'
import styled from '../../../utils/styled'

const ProgressValue = styled(Typography).attrs(() => ({
  variant: 'body2',
  color: 'textSecondary'
}))`
  font-size: 0.8rem;
  opacity: 0.5;
  margin-left: ${(props) => props.theme.spacing(0.5)};
  vertical-align: text-bottom;
`

const LinearProgressWithLabel = (props) => (
  <Box display='flex' alignItems='center'>
    <Box width='100%' mr={1}>
      <LinearProgress variant='determinate' {...props} />
    </Box>
    <Box minWidth={35}>
      <ProgressValue>{props.value !== null ? `${Math.round(props.value)}%` : ''}</ProgressValue>
    </Box>
  </Box>
)

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number
}

export default LinearProgressWithLabel
