import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { START_INSTANCE, STOP_INSTANCE } from '../../../api/instances'

const useAction = ({ name, status, onActioned }) => {
  const { enqueueSnackbar } = useSnackbar()

  // It takes a while for the state of the instance to change so we're faking the loading state
  // so the user is not confused by the button not changing state immediately
  const [fakeLoadingState, setFakeLoadingState] = useState(false)

  const [start, { loading: startLoading }] = useMutation(START_INSTANCE)
  const [stop, { loading: stopLoading }] = useMutation(STOP_INSTANCE)

  const onStart = async () => {
    const {
      data: {
        instanceStart: { success }
      }
    } = await start({ variables: { name } })
    if (success) {
      enqueueSnackbar({
        message: (
          <span>
            Instance <b>{name}</b> is now starting, this may take up to 5 minutes.
          </span>
        ),
        variant: 'success'
      })
    } else {
      enqueueSnackbar({
        message: (
          <span>
            Failed to start instance <b>{name}.</b>
          </span>
        ),
        variant: 'error'
      })
    }
    setFakeLoadingState(success)
    onActioned()
  }

  const onStop = async () => {
    const {
      data: {
        instanceStop: { success }
      }
    } = await stop({ variables: { name } })
    if (success) {
      enqueueSnackbar({
        message: (
          <span>
            Instance <b>{name}</b> is now stopping, this may take a while.
          </span>
        ),
        variant: 'success'
      })
    } else {
      enqueueSnackbar({
        message: (
          <span>
            Failed to stop instance <b>{name}</b>.
          </span>
        ),
        variant: 'error'
      })
    }
    setFakeLoadingState(success)
    onActioned()
  }

  const loading = startLoading || stopLoading || fakeLoadingState || status === 'starting' || status === 'stopping'

  useEffect(() => {
    if (status === 'starting' || status === 'stopping') {
      setFakeLoadingState(false)
    }
  }, [status])

  return { onStart, onStop, loading }
}

export default useAction
