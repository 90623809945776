import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import BaseInstructions from './base'
import TrayArrowDown from 'mdi-material-ui/TrayArrowDown'

const AndroidSimulatorInstructions = ({ build }) => {
  if (!build) {
    return null
  }

  return (
    <BaseInstructions
      version={build.version}
      runtimeVersion={build.runtimeVersion}
      header='To install the app on Android Simulator'
      footer='Open Android Simulator and drag the APK file onto the Simulator window.'
    >
      <Button
        variant='outlined'
        color='primary'
        href={build.buildUrl}
        download
        sx={{ flexDirection: 'column', height: 150, p: 3 }}
      >
        <TrayArrowDown sx={{ mb: 2 }} />
        Download this file
      </Button>
    </BaseInstructions>
  )
}

AndroidSimulatorInstructions.propTypes = {
  build: PropTypes.shape({
    appName: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    runtimeVersion: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    gitRevision: PropTypes.string.isRequired,
    buildUrl: PropTypes.string.isRequired
  })
}

export default AndroidSimulatorInstructions
