import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { enqueueSnackbar } from 'notistack'
// import { setContext } from '@apollo/client/link/context'
// import getCsrfToken from "../utils/csrf_token"

const httpLink = createHttpLink({ uri: '/api' })

// This is causing issues, disabling for now, may revisit this decision in the future
//
// const csrfTokenLink = setContext((_, { headers }) => ({
//   headers: {
//     ...headers,
//     "X-CSRF-TOKEN": getCsrfToken(),
//   },
// }))

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => enqueueSnackbar(message, { variant: 'error', preventDuplicate: true }))
  } else if (networkError) {
    enqueueSnackbar(`[Network error]: ${networkError}`, { variant: 'error', preventDuplicate: true })
  }
})

// link: from([errorLink, csrfTokenLink.concat(httpLink)]),
const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache()
})

export default client
