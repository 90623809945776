import React from 'react'
import PropTypes from 'prop-types'
import { useConfig } from '../../../config'
import { Button, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material'
import StatusIndicator from './status_indicator'
import { BACKEND, NATIVE, WEB } from '../../../constants/app_types'
import SecondaryLabel from './secondary_label'
import { WHITE } from '../../../constants/brand'
import { styled } from '@mui/material/styles'
import Revision from '../../revision'

const BuildItem = styled(ListItem)`
  &.MuiListItem-secondaryAction {
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`

const BuildIcon = styled(ListItemIcon)`
  min-width: 36px;
`

const BuildName = styled(ListItemText)`
  flex: 1;
  flex-direction: row;
`

const BuildNameContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const AppType = styled('span')`
  background-color: #b0b0b0;
  padding: 2px 5px;
  margin-left: ${(props) => props.theme.spacing(0.5)};
  border-radius: 2px;
  font-size: 10px;
  color: ${WHITE};
  text-shadow: 0 -1px #aaa;
  text-transform: uppercase;
`

const BuildActions = styled(ListItemSecondaryAction)`
  position: static;
  transform: none;
  top: initial;
  left: initial;
  padding-right: ${(props) => props.theme.spacing(2)};
  white-space: nowrap;
`

const Build = ({ build }) => {
  const config = useConfig()
  const { apps } = config

  const isWebOnly = (appName) => !!apps[appName] && (apps[appName].type === WEB || apps[appName].type === BACKEND)

  return (
    <BuildItem>
      <BuildIcon>
        <StatusIndicator status={build.status} progress={build.progress} />
      </BuildIcon>
      <BuildName
        primary={
          <BuildNameContent>
            <div>
              {build.app}
              {!isWebOnly(build.app) && <AppType>{build.appType === WEB ? 'WEB' : 'APP'}</AppType>}
              {build.revision && (
                <Typography component='span' variant='subtitle2' sx={{ ml: 1 }}>
                  <Revision app={build.app} revision={build.revision} short lazyLoad />
                </Typography>
              )}
            </div>
          </BuildNameContent>
        }
        secondary={
          build.error || (build.progress !== -1 && build.progress !== 100) ? <SecondaryLabel build={build} /> : null
        }
        disableTypography
      />
      <BuildActions>
        {build.buildUrl && (
          <Button component='a' href={build.buildUrl} target='_blank' size='small'>
            STATUS
          </Button>
        )}
      </BuildActions>
    </BuildItem>
  )
}

Build.propTypes = {
  build: PropTypes.shape({
    id: PropTypes.number,
    app: PropTypes.string,
    app_type: PropTypes.oneOf([WEB, NATIVE, BACKEND]),
    service_type: PropTypes.oneOf(['jenkins', 'semaphore']),
    build_url: PropTypes.string,
    buildName: PropTypes.string,
    status: PropTypes.string,
    progress: PropTypes.number
  }),
  instanceId: PropTypes.string
}

export default Build
