import React from 'react'
import styled from '../../utils/styled'
import { Card, Box, Typography } from '@mui/material'
import Github from 'mdi-material-ui/Github'

const Container = styled(Box)`
  background: #171515;
  color: #fff;
  padding: ${(props) => props.theme.spacing(1, 2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Footer = styled(Typography).attrs(() => ({
  color: 'inherit',
  variant: 'caption'
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #fff;
`

const Content = styled(Box)`
  padding: ${(props) => props.theme.spacing(2)};
`

const Description = styled(Typography).attrs(() => ({
  variant: 'body2',
  display: 'block'
}))`
  max-height: ${(props) => props.theme.spacing(15)};
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TooltipContent = ({ message, author, ...props }) => (
  <Card elevation={3} {...props}>
    <Content>
      <Description>{message}</Description>
    </Content>
    <Container>
      <Footer>
        <div>Author: {author}</div>
        <Github />
      </Footer>
    </Container>
  </Card>
)

export default TooltipContent
