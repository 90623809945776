import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { REFRESH_DB_TASKS_FOR_INSTANCE, REFRESH_DB } from '../../../api/refresh_db'

const useRefreshDb = ({ name }) => {
  const { data } = useQuery(REFRESH_DB_TASKS_FOR_INSTANCE, { variables: { instanceName: name }, pollInterval: 5000 })
  const [refreshDb, { loading: refreshDbLoading }] = useMutation(REFRESH_DB, { variables: { instanceName: name } })
  const [fakeLoading, setFakeLoading] = useState(false)

  const latestTask = data?.refreshDbTasks?.[0]
  const loading = latestTask?.building || refreshDbLoading || fakeLoading
  const warning = !loading && latestTask && !latestTask.success

  const onClick = () => refreshDb().then(() => setFakeLoading(true))

  return { loading, warning, onClick }
}

export default useRefreshDb
