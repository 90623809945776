import styled from '../../../utils/styled'
import logo from './logo.svg'

const Logo = styled('img').attrs(() => ({
  src: logo
}))`
  width: 40px;
  height: 40px;
`

export default Logo
