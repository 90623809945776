import React from 'react'
import PropTypes from 'prop-types'
import Alert from 'mdi-material-ui/Alert'
import BaseInstructions from './base'
import { GOLD_2 } from '../../../../constants/brand'

const MissingInstructions = ({ platform, environment }) => {
  return (
    <BaseInstructions
      header='This version of the app is no longer available'
      footer={
        <>
          <b>Please request a developer from your team to rebuild this application for you.</b>
          <br />
          Platform: {platform}, environment: {environment}
        </>
      }
    >
      <Alert sx={{ width: 110, height: 110 }} htmlColor={GOLD_2} />
    </BaseInstructions>
  )
}

MissingInstructions.propTypes = {
  platform: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired
}

export default MissingInstructions
