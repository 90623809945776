import { Card, CardContent, CardActions, Typography } from '@mui/material'
import React from 'react'
import styled from '../utils/styled'
import { GRAY_5 } from '../constants/brand'

// prettier-ignore
const StyledCard = styled(Card)`
  ${(props) => (
    props.$noExtraBottomPadding
? `
      & .MuiCardContent-root {
        padding-bottom: ${props.theme.spacing(2)}
      }
    `
: '')}
`

const StyledCardActions = styled(CardActions)`
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing(1)};
  background: ${({ $fullColor }) => ($fullColor ? 'transparent' : GRAY_5)};
  color: ${({ $fullColor }) => ($fullColor ? 'white' : 'inherit')};
`

const SimplifiedCard = ({ title, children, noExtraBottomPadding, actions, fullColor, contentStyle = {}, ...props }) => (
  <StyledCard $noExtraBottomPadding={noExtraBottomPadding} elevation={1} {...props}>
    <CardContent style={contentStyle}>
      {title && (
        <Typography variant='h5' color='text.primary' gutterBottom sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}
      {children}
    </CardContent>
    {actions && <StyledCardActions $fullColor={fullColor}>{actions}</StyledCardActions>}
  </StyledCard>
)
export default SimplifiedCard
