import React from 'react'
import styled from '../../../../utils/styled'
import { CRIMSON_1, GRAY_2, GRAY_3, GREEN_GROWN_UP_GREEN, TEAL_1 } from '../../../../constants/brand'
import { Dot, SubmenuItem, SubmenuIcon, SubmenuLink, Submenu } from './_components'
import { FETCH_DEPLOYMENTS } from '../../../../api/deployments'
import { useStore } from '../../../../store'
import { useQuery } from '../../../../utils/apollo'
import { FAILURE, IN_PROGRESS, SUCCESS } from '../../../../constants/statuses'
import relativeDate from 'tiny-relative-date'
import { useIsActiveUrl } from '../../../../utils/use_is_active_url'
import { Skeleton } from '@mui/material'

const dotColor = (status) => {
  if (status === SUCCESS) return GREEN_GROWN_UP_GREEN
  if (status === IN_PROGRESS) return TEAL_1
  if (status === FAILURE) return CRIMSON_1
  return GRAY_2
}

const Title = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const CreatedAt = styled('span')`
  color: ${GRAY_3};
  font-size: 12px;
`

const Issue = styled('div')`
  font-size: 12px;
  color: ${TEAL_1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 260px;
`

const SidebarSubmenuDeployments = () => {
  const store = useStore()
  const isActiveURL = useIsActiveUrl()
  const username = store.session.currentUser.email

  const { data, loading } = useQuery(FETCH_DEPLOYMENTS, {
    variables: { username, limit: 5 },
    fetchPolicy: 'cache-only'
  })

  const lastDeployments = data?.deployments?.nodes || []

  return (
    <Submenu $empty={!loading && lastDeployments.length === 0}>
      {loading &&
        new Array(5).fill(0).map((_, i) => (
          <SubmenuItem key={i}>
            <SubmenuIcon>
              <Dot color={GREEN_GROWN_UP_GREEN} size={6} />
            </SubmenuIcon>
            <Skeleton variant='text' width='145px' sx={{ ml: '5px', mr: '38px' }} />
            <Skeleton variant='text' width='80px' />
          </SubmenuItem>
        ))}
      {lastDeployments.map((deployment) => (
        <SubmenuItem key={deployment.id} $isActive={isActiveURL(`/build/${deployment.id}`)}>
          <SubmenuIcon>
            <Dot color={dotColor(deployment.status)} size={6} />
          </SubmenuIcon>
          <SubmenuLink to={`/build/${deployment.id}`}>
            <Title>
              <span>#{deployment.id}</span>
              <CreatedAt>{relativeDate(deployment.createdAt)}</CreatedAt>
            </Title>
            {deployment.jiraIssues &&
              deployment.jiraIssues.map((issue) => (
                <Issue key={issue.key}>
                  {issue.key} {issue.title}
                </Issue>
              ))}
          </SubmenuLink>
        </SubmenuItem>
      ))}
    </Submenu>
  )
}

export default SidebarSubmenuDeployments
