import React from 'react'
import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import ReservationForm from '../../reservation_form'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
`

const Checkboxes = ({ app, deployment }) => {
  const { reserve, setReserve, reserveUntil, setReserveUntil, instances } = deployment

  return (
    <Container>
      <FormControlLabel
        control={
          <Checkbox
            name='runMigrations'
            color='primary'
            disabled={!app}
            checked={!!app?.runMigrations}
            onChange={() => app?.setRunMigrations(!app?.runMigrations)}
          />
        }
        label='Run database migrations'
      />
      <FormControlLabel
        control={
          <Checkbox
            name='refreshTranslations'
            color='primary'
            disabled={!app}
            checked={!!app?.refreshTranslations}
            onChange={() => app?.setRefreshTranslations(!app?.refreshTranslations)}
          />
        }
        label='Refresh translations'
      />
      <FormControlLabel
        control={
          <Checkbox
            name='reserveInstances'
            color='primary'
            disabled={instances.length === 0}
            checked={reserve}
            onChange={() => {
              setReserve(!reserve)
              if (!reserve) {
                setReserveUntil(null)
              }
            }}
          />
        }
        label='Reserve the instances'
      />
      {reserve && (
        <ReservationForm
          activeUntil={reserveUntil}
          setActiveUntil={setReserveUntil}
          single={instances.length === 1}
          sx={{ mt: 2 }}
        />
      )}
    </Container>
  )
}

export default observer(Checkboxes)
