import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'
import Alert from 'mdi-material-ui/Alert'

const RevisionError = ({ message }) => (
  <Box alignItems='center' display='flex'>
    <Alert sx={{ fontSize: 14, marginRight: 0.5, color: 'error.main' }} />

    <Typography variant='caption' color='error.main'>
      {message}
    </Typography>
  </Box>
)

RevisionError.propTypes = {
  message: PropTypes.string.isRequired
}

export default RevisionError
