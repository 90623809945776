import React from 'react'
import { Chip, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react'
import { useTheme } from '@mui/material/styles'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import Check from 'mdi-material-ui/Check'
import Close from 'mdi-material-ui/Close'
import styled from '../../../utils/styled'
import { FAILURE, IN_PROGRESS, SUCCESS } from '../../../constants/statuses'

const StatusChip = styled(React.memo(({ htmlColor, ...props }) => <Chip {...props} />)).attrs((props) => ({
  variant: 'outlined',
  label: props.children
}))`
  color: ${(props) => props.htmlColor};
  border-color: ${(props) => props.htmlColor};
`

const StatusLabelComponent = ({ color, icon: Icon, iconSize, loading, children, ...props }) => (
  <StatusChip
    icon={
      loading
        ? (
          <CircularProgress size={18} color='info' sx={{ marginLeft: 0.5 }} />
          )
        : (
            Icon && <Icon htmlColor={color} style={{ color, fontSize: iconSize || 22 }} />
          )
    }
    htmlColor={color}
    color={loading ? 'info' : undefined}
    {...props}
  />
)

// TODO: finish this component, style chip to be rectangular, fix icons to be outline versions, add colors
const StatusLabel = ({ status }) => {
  const theme = useTheme()

  if (status === SUCCESS) {
    return <StatusLabelComponent color={theme.palette.success.dark} icon={Check} iconSize={20} label='Success' />
  }

  if (status === FAILURE) {
    return <StatusLabelComponent color={theme.palette.error.main} icon={Close} iconSize={22} label='Failure' />
  }

  if (status === IN_PROGRESS) {
    return <StatusLabelComponent color={theme.palette.info.light} loading label='In progress' />
  }

  if (status === null) {
    return <StatusLabelComponent color='#ccc' loading label='Starting' />
  }

  return <StatusLabelComponent color={theme.palette.warning.main} icon={HelpCircleOutline} label='Unknown' />
}

export default observer(StatusLabel)
