import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import { SnackbarProvider, closeSnackbar } from 'notistack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { IconButton } from '@mui/material'
import Close from 'mdi-material-ui/Close'
import { ConfigContext } from './config'
import store, { StoreContext } from './store'
import client from './api/client'
import AppContainer from './components/layout/app_container'
import ErrorBoundary from './components/error_boundary'
import SessionManager from './components/session_manager'

import Index from './components/pages/main'
import Create from './components/pages/create'
import Build from './components/pages/build'
import List from './components/pages/list'
import Instances from './components/pages/instances'
import Instance from './components/pages/instance'
import Apps from './components/pages/apps'
import MigrationDialog from './components/migration_dialog'

import squirrelTheme from './theme'

import '@fontsource/nunito-sans/400'
import '@fontsource/nunito-sans/600'
import '@fontsource/nunito-sans/700'
import '@fontsource/nunito-sans/800'
import './sentry'

const theme = createTheme(squirrelTheme)

const App = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ApolloProvider client={client}>
        <SnackbarProvider
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)} size='small' color='inherit'>
              <Close />
            </IconButton>
          )}
          maxSnack={16}
        >
          <StoreContext.Provider value={store}>
            <ConfigContext.Provider value={props}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <ErrorBoundary>
                    <SessionManager>
                      <Router>
                        <AppContainer>
                          <Routes>
                            <Route path='/build/:id' element={<Build />} />
                            <Route path='/create' element={<Create />} />
                            <Route path='/list/:page?' element={<List />} />
                            <Route path='/instances/:name' element={<Instance />} />
                            <Route path='/instances' element={<Instances />} />
                            <Route path='/apps/:appName?' element={<Apps />} />
                            <Route path='/' element={<Index />} />
                          </Routes>
                          <MigrationDialog />
                        </AppContainer>
                      </Router>
                    </SessionManager>
                  </ErrorBoundary>
                </ThemeProvider>
              </StyledEngineProvider>
            </ConfigContext.Provider>
          </StoreContext.Provider>
        </SnackbarProvider>
      </ApolloProvider>
    </LocalizationProvider>
  )
}

export default App
